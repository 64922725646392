import React from 'react';
import {Col, Collapse, Form, Row} from "react-bootstrap";
import {Controller} from "react-hook-form";
import WarehouseOperationSelectStatus from "../WarehouseOperationSelectStatus";
import DatePicker from "react-datepicker";
import {addDays} from "date-fns";
import {useSelector} from "react-redux";
import {selectDateFormat, selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import ContractorSelect from "../../../common/ContractorSelect";
import PropTypes from "prop-types";
import SelectWarehouse from "../../../../common/SelectWarehouse";
import EmployeeSelect from "../../../common/EmployeeSelect";
import SelectOrganizationAccount from "../../../../common/SelectOrganizationAccount";

const DatatableHeaderFilter = ({name, contractor, predefinedFilters, isOpenFilterCollapse}) => {
    const t = Translate
    const lang = useSelector(selectLang)
    const dateFormat = useSelector(selectDateFormat);

    return (
        <>
            <Row className="mb-3 g-3">
                <h5 className="fs-1 mb-0 text-nowrap py-2 py-xl-0">{name}</h5>
            </Row>

            <Collapse in={isOpenFilterCollapse}>
                <Row className="mb-3 g-3">
                    <Form.Group as={Col} md={4} xl={3}>
                        <Form.Label>{t(lang, "warehouse.operation.item.common.sale_status")}</Form.Label>
                        <Controller name="is_approved"
                                    render={({field}) => (
                                        <WarehouseOperationSelectStatus defaultValue={field.value}
                                                                        onChange={data => field.onChange(data.value)}
                                        />
                                    )}
                        />
                    </Form.Group>

                    {
                        contractor && <Form.Group as={Col} md={4} xl={3}>
                            <Form.Label>{t(lang, "warehouse.operation.item.common.client")}</Form.Label>
                            <Controller name="contractor_id"
                                        render={({field}) => {
                                            return (
                                                <ContractorSelect defaultValue={field?.value}
                                                                  isClearable={true}
                                                                  classNamePrefix="react-select"
                                                                  isDisabled={predefinedFilters.hasOwnProperty('contractor_id')}
                                                                  onChange={(option) => {
                                                                      field.onChange(option ? option?.id : null)
                                                                  }}
                                                />
                                            )
                                        }}
                            />
                        </Form.Group>
                    }
                    <Form.Group as={Col} md={4} xl={3}>
                        <Form.Label>{t(lang, "warehouse.operation.item.common.filter.date_start")}</Form.Label>
                        <Controller name="date_time_start"
                                    render={({field}) => (
                                        <DatePicker selected={field.value}
                                                    className='form-control'
                                                    placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                                    dateFormat={`${dateFormat} HH:mm`}
                                                    onChange={field.onChange}
                                                    showTimeSelect
                                                    timeFormat="p"
                                                    timeIntervals={60}
                                        />
                                    )}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={4} xl={3}>
                        <Form.Label>{t(lang, "warehouse.operation.item.common.filter.date_end")}</Form.Label>
                        <Controller name="date_time_end"
                                    render={({field}) => (
                                        <DatePicker selected={field.value}
                                                    onChange={field.onChange}
                                                    className='form-control'
                                                    placeholderText={t(lang, "warehouse.operation.item.common.filter.date:placeholder")}
                                                    dateFormat={`${dateFormat} HH:mm`}
                                                    maxDate={addDays(new Date(), 5)}
                                                    showTimeSelect
                                                    timeFormat="p"
                                                    timeIntervals={60}
                                        />
                                    )}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={4} xl={3}>
                        <Form.Label>{t(lang, "warehouse.navbar.routes.warehouse")}</Form.Label>
                        <Controller name={'warehouse_id'}
                                    render={({field}) => (
                                        <SelectWarehouse defaultWarehouseId={field.value}
                                                         onChange={(option) => field.onChange(option ? option.id : null)}
                                        />
                                    )}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={4} xl={3}>
                        <Form.Label>{t(lang, "warehouse.operation.item.common.employee")}</Form.Label>
                        <Controller name={'employee_id'}
                                    render={({field}) => (
                                        <EmployeeSelect defaultValue={field.value}
                                                        onChange={(option) => field.onChange(option ? option.id : null)}
                                                        isClearable={true}
                                        />
                                    )}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md={4} xl={3}>
                        <Form.Label>{t(lang, "crm.common.datatable.username")}</Form.Label>
                        <Controller name={'operation_account_id'}
                                    render={({field}) => (
                                        <SelectOrganizationAccount defaultAccountId={field.value}
                                                                   onChange={(option) => field.onChange(option ? option.id : null)}
                                        />
                                    )}
                        />
                    </Form.Group>
                </Row>
            </Collapse>
        </>
    );
};

DatatableHeaderFilter.propTypes = {
    predefinedFilters: PropTypes.object,
}

DatatableHeaderFilter.defaultProps = {
    predefinedFilters: {},
}

export default DatatableHeaderFilter;
