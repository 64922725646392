import {Card, Dropdown, OverlayTrigger, Popover} from "react-bootstrap";
import AdvanceTable from "../../../../common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "../../../../common/advance-table/AdvanceTableWrapper";
import SaleDatatableHeader from "./SaleDatatableHeader";
import {useDispatch, useSelector} from "react-redux";
import {
    changeFilterOptionsAsync, saleLoading,
    selectSaleCount,
    selectSaleFilterOptions,
    selectSaleItems
} from "../../../../../app/store/reducers/warehouse-operation/saleReducer";
import AdvanceTableCustomPagination from "../../../../common/advance-table/AdvanceTableCustomPagination";
import React from "react";
import SoftBadge from "../../../../common/SoftBadge";
import {Link} from "react-router-dom";
import dayjs from "dayjs";
import {selectLang, selectNumberFormat, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import numeral from "numeral";
import {faFileExcel, faFilePdf, faPrint} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    isApprovedCheckedWarehouseOperation, ItemDiscountsTotalSumGroupCurrencyId,
    ItemsTotalSumCashBoxGroupPaymentTypeCurrencyId, ItemsTotalSumDebtsGroupPaymentTypeCurrencyId,
    ItemsTotalSumGroupCurrencyId,
    OperationInfoPaymentType, OperationTotalSum,
    OperationTotalSumInfoPayment,
} from "../../enum/warehouseOperationWrapper";
import {
    GetPaymentTypeText, PaymentTypeBankTransferCode,
    PaymentTypeCashCode, PaymentTypeClickCode, PaymentTypeHumoCode,
    PaymentTypePaymeCode, PaymentTypeUzCardCode, PaymentTypeVisaCode
} from "../../../../../enum/ContractorWrapper";
import SaleDelete from "../components/SaleDelete";
import CardDropdown from "../../../../common/CardDropdown";
import IconButton from "../../../../common/IconButton";
import PrintSaleDropdown from "../../components/PrintSaleDropdown";
import DownloadSaleDropdown from "../../components/DownloadSaleDropdown";
import PropTypes from "prop-types";
import UploadSaleToExcel from "../excel/UploadSaleToExcel";


const SaleDatatable = ({predefinedFilters, loadSales}) => {
    const saleItems = useSelector(selectSaleItems)
    const numberFormat = useSelector(selectNumberFormat);
    const filters = useSelector(selectSaleFilterOptions)
    const dispatch = useDispatch()
    const count = useSelector(selectSaleCount)
    const lang = useSelector(selectLang)
    const t = Translate
    const loading = useSelector(saleLoading)

    const totalSumArray = ItemsTotalSumGroupCurrencyId(saleItems);
    const totalSumCashBoxPaymentType = ItemsTotalSumCashBoxGroupPaymentTypeCurrencyId(saleItems);
    const totalSumbOfPayments = totalSumCashBoxPaymentType?.reduce((acc, item) => {
        if (acc[item.name])
            acc[item.name] += item.totalSum;
        else
            acc[item.name] = item.totalSum;

        return acc;
    }, {});
    const totalSumDebtsPaymentType = ItemsTotalSumDebtsGroupPaymentTypeCurrencyId(saleItems);
    const debtTypeCurrency = totalSumDebtsPaymentType.map(x => {
        return {
            id: x.currency_id,
            name: x.name,
            totalSum: x.totalSum
        }
    })
    const discountTotalSum = ItemDiscountsTotalSumGroupCurrencyId(saleItems);

    // debt calc
    // {
    //     totalSumArray?.forEach(itemPrices => {
    //         if (itemPrices.currency_id) {
    //             const foundDebtState = totalSumDebtsPaymentType?.find(x => x.currency_id === itemPrices.currency_id);
    //             const totalSum = foundDebtState ? itemPrices.totalSum - foundDebtState.totalSum : itemPrices.totalSum;
    //
    //             debtTypeCurrency.push({
    //                 id: itemPrices.currency_id,
    //                 name: itemPrices.name,
    //                 totalSum: totalSum
    //             });
    //         }
    //     });
    //
    //     totalSumDebtsPaymentType?.forEach(itemPrices => {
    //         if (itemPrices.currency_id) {
    //             const foundSeparationCurrency = totalSumArray.find(separationCurrency => separationCurrency.currency_id === itemPrices.currency_id);
    //             const totalSum = foundSeparationCurrency ? itemPrices.totalSum - foundSeparationCurrency.totalSum : itemPrices.totalSum;
    //
    //             if (!foundSeparationCurrency) {
    //                 debtTypeCurrency.push({
    //                     id: itemPrices.currency_id,
    //                     name: itemPrices.name,
    //                     totalSum: totalSum
    //                 });
    //             }
    //         }
    //     });
    // }

    const columns = [
        {
            Header: "#",
            id: "row",
            filterable: false,
            cellProps: {
                width: '35px',
            },
            Cell: ({row}) => {
                return <>
                    <OverlayTrigger trigger={['hover', 'focus']}
                                    placement={'bottom'}
                                    overlay={
                                        <Popover id="popover-basic" className='mt-0'>
                                            <Popover.Header>
                                                <SoftBadge className={'d-block text-center fs--1'}
                                                           bg={row?.original?.is_approved ? 'success' : 'warning'}>{t(lang, isApprovedCheckedWarehouseOperation(row?.original?.is_approved))}</SoftBadge>
                                            </Popover.Header>
                                        </Popover>
                                    }
                    >
                        <SoftBadge className={'d-block text-center fs--1 cursor-pointer'}
                                   bg={row?.original?.is_approved ? 'success' : 'warning'}>
                            {(filters.page - 1) * filters.limit + row.index + 1}
                        </SoftBadge>
                    </OverlayTrigger>
                </>
            }
        },
        {
            accessor: 'number',
            Header: t(lang, "Номер"),
            Cell: ({row: {original}}) => {
                return (
                    <Link to={`/warehouse/operation/sales/view/${original?.id}`} className="d-flex text-wrap">
                        {original?.number}
                    </Link>
                )
            }
        },
        {
            accessor: 'contractor.name',
            Header: t(lang, "warehouse.operation.item.common.client"),
            totalInfo: `${t(lang, "Итого:")}`,
            totalInfoClassName: 'fs-1 fw-bold text-nowrap text-end',
            cellProps: {
                maxwidth: "250px",
                minwidth: "250px"
            },
            Cell: ({row: {original}}) => {
                return (
                    <Link to={`/crm/contractor-card/${original?.contractor?.id}`} className="d-flex text-wrap"
                          style={{maxWidth: "250px", minWidth: "250px"}}>
                        {original?.contractor?.name}
                    </Link>
                )
            }
        },
        {
            Header: t(lang, "warehouse-operation.sale.datatable.sale_total_price"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumArray?.map((totalSum, index) => {
                return (
                    <div key={index}>
                        <OverlayTrigger trigger={['hover', 'focus']}
                                        placement={'bottom'}
                                        overlay={
                                            <Popover id="popover-basic" className='mt-0'>
                                                <Popover.Header>
                                                    <SoftBadge className={'d-block text-center fs--1'}
                                                               bg="info">{t(lang, "warehouse.operation.item.common.total_sum")}</SoftBadge>
                                                </Popover.Header>
                                            </Popover>
                                        }
                        >
                            <SoftBadge bg="info" className="cursor-pointer">
                                {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                &nbsp;
                                <span className="fw-bold">{totalSum?.name}</span>
                            </SoftBadge>
                        </OverlayTrigger>
                    </div>
                )
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const operationTotalSum = OperationTotalSum(original?.items?.filter(f => !f?.is_deleted))


                return operationTotalSum?.map((item, index) => {
                    return (
                        <div className={'fs-0'} key={index}>
                            <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"info"}>
                                {numeral.formats[numberFormat].format(item?.amount)}
                                &nbsp;
                                {item?.currency?.name}
                            </SoftBadge>
                        </div>
                    )
                })
            }
        },
        {
            Header: t(lang, "warehouse-operation.sale.datatable.sale_payment_total_price"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: Object.keys(totalSumbOfPayments)?.map((key) => {
                return (
                    <div key={key}>
                        <OverlayTrigger trigger={['hover', 'focus']}
                                        placement={'bottom'}
                                        overlay={
                                            <Popover id="popover-basic" className='mt-0'>
                                                <Popover.Header>
                                                    <SoftBadge className={'d-block text-center fs--1'}
                                                               bg="success">{t(lang, "warehouse.operation.item.common.total_sum")}</SoftBadge>
                                                </Popover.Header>
                                            </Popover>
                                        }
                        >
                            <SoftBadge bg="success" className="cursor-pointer">
                                {numeral.formats[numberFormat].format(totalSumbOfPayments[key])}
                                &nbsp;
                                <span className="fw-bold">{key}</span>
                            </SoftBadge>
                        </OverlayTrigger>
                    </div>
                )
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationTotalSumInfoPayment(original?.payment?.cash_box_states);
                if (separationCurrencyCalc) {
                    const total = separationCurrencyCalc.reduce((acc, item) => {
                        if (acc[item.name])
                            acc[item.name] += item.totalSum;
                        else
                            acc[item.name] = item.totalSum;

                        return acc;
                    }, {});

                    return Object.keys(total).map((key) => (
                        <div className={'fs-0'} key={key}>
                            <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                {numeral.formats[numberFormat].format(total[key])}
                                &nbsp;
                                {key}
                            </SoftBadge>
                        </div>
                    ))
                }
                return ""
            }
        },
        {
            Header: t(lang, "warehouse.operation.item.common.discount"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: discountTotalSum?.map((item, index) => {
                return (
                    <div key={index}>
                        <OverlayTrigger trigger={['hover', 'focus']}
                                        placement={'bottom'}
                                        overlay={
                                            <Popover id="popover-basic" className='mt-0'>
                                                <Popover.Header>
                                                    <SoftBadge className={'d-block text-center fs--1'}
                                                               bg="primary">{t(lang, "warehouse.operation.item.common.discount")}</SoftBadge>
                                                </Popover.Header>
                                            </Popover>
                                        }
                        >
                            <SoftBadge bg="primary" className="cursor-pointer">
                                {numeral.formats[numberFormat].format(item?.totalSum || 0)}
                                &nbsp;
                                <span className="fw-bold">{item?.name}</span>
                            </SoftBadge>
                        </OverlayTrigger>
                    </div>
                )
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {

                return original.exact_discounts.length > 0 ? original.exact_discounts?.map((item, index) => {
                    return (
                        <div className={'fs-0'} key={index}>
                            <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"primary"}>
                                {numeral.formats[numberFormat].format(item?.amount)}
                                &nbsp;
                                {item?.currency?.name}
                            </SoftBadge>
                        </div>
                    )
                }) : original?.percent_discount ? original.overall_discounts.map((item, index) => {
                    return (
                        <div className={'fs-0'} key={index}>
                            <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"primary"}>
                                {numeral.formats[numberFormat].format(item?.amount)}
                                &nbsp;
                                {item?.currency?.name}
                            </SoftBadge>
                        </div>
                    )
                }) : null
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypeCashCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypeCashCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypeCashCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationTotalSumInfoPayment(original?.payment?.cash_box_states)


                if (separationCurrencyCalc) {
                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypeCashCode === item?.payment_type) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypeUzCardCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypeUzCardCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypeUzCardCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationInfoPaymentType(original?.payment?.cash_box_states)[PaymentTypeUzCardCode]


                if (separationCurrencyCalc) {

                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypeUzCardCode === item?.payment_type && item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypeHumoCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypeHumoCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypeHumoCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationInfoPaymentType(original?.payment?.cash_box_states)[PaymentTypeHumoCode]


                if (separationCurrencyCalc) {

                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypeHumoCode === item?.payment_type && item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypeBankTransferCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypeBankTransferCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypeBankTransferCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationInfoPaymentType(original?.payment?.cash_box_states)[PaymentTypeBankTransferCode]


                if (separationCurrencyCalc) {

                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypeBankTransferCode === item?.payment_type && item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypeClickCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypeClickCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypeClickCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationInfoPaymentType(original?.payment?.cash_box_states)[PaymentTypeClickCode]


                if (separationCurrencyCalc) {

                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypeClickCode === item?.payment_type && item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypePaymeCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypePaymeCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypePaymeCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationInfoPaymentType(original?.payment?.cash_box_states)[PaymentTypePaymeCode]


                if (separationCurrencyCalc) {

                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypePaymeCode === item?.payment_type && item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, GetPaymentTypeText(PaymentTypeVisaCode)),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            totalInfo: totalSumCashBoxPaymentType.map((totalSum, index) => {

                if (totalSum?.payment_type === PaymentTypeVisaCode) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg="success">{t(lang, GetPaymentTypeText(PaymentTypeVisaCode))}</SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg="success" className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(totalSum?.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{totalSum?.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const separationCurrencyCalc = OperationInfoPaymentType(original?.payment?.cash_box_states)[PaymentTypeVisaCode]


                if (separationCurrencyCalc) {

                    return separationCurrencyCalc?.map((item, index) => {
                        if (PaymentTypeVisaCode === item?.payment_type && item?.totalSum) {
                            return (
                                <div className={'fs-0'} key={index}>
                                    <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={"success"}>
                                        {numeral.formats[numberFormat].format(item?.totalSum)}
                                        &nbsp;
                                        {item?.name}
                                    </SoftBadge>
                                </div>
                            )
                        }
                        return ""
                    })
                }
                return ""
            }
        },
        {
            Header: t(lang, "warehouse.operation.item.common.debt_state"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },

            totalInfo: debtTypeCurrency?.map((x, index) => {
                if (x.totalSum) {
                    return (
                        <div key={index}>
                            <OverlayTrigger trigger={['hover', 'focus']}
                                            placement={'bottom'}
                                            overlay={
                                                <Popover id="popover-basic" className='mt-0'>
                                                    <Popover.Header>
                                                        <SoftBadge className={'d-block text-center fs--1'}
                                                                   bg={x?.totalSum > 0 ? "danger" : "warning"}>
                                                            {x?.totalSum > 0 ?
                                                                t(lang, "warehouse.operation.item.common.debt_state")
                                                                :
                                                                t(lang, "Я должен заплатить")
                                                            }
                                                        </SoftBadge>
                                                    </Popover.Header>
                                                </Popover>
                                            }
                            >
                                <SoftBadge bg={x?.totalSum > 0 ? "danger" : "warning"} className="cursor-pointer">
                                    {numeral.formats[numberFormat].format(x.totalSum)}
                                    &nbsp;
                                    <span className="fw-bold">{x.name}</span>
                                </SoftBadge>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }),
            totalInfoClassName: "fw-bold fs-1 text-end text-nowrap",
            Cell: ({row: {original}}) => {
                const debtTypeCurrency = []

                // debt calc
                {
                    original?.net_price?.forEach(price => {
                        if (price?.currency?.id) {
                            const foundDebtState = original?.payment?.debt_states?.find(debtState => debtState.currency.id === price?.currency?.id);
                            const totalSum = foundDebtState ? price?.amount - foundDebtState.amount : price?.amount;

                            debtTypeCurrency.push({
                                id: price?.currency?.id,
                                name: price?.currency?.name,
                                totalSum: totalSum
                            });
                        }
                    });

                    original?.payment?.debt_states?.forEach(debt => {
                        if (debt.currency.id) {
                            const foundSeparationCurrency = original?.net_price?.find(price => price?.currency?.id === debt.currency.id);
                            const totalSum = foundSeparationCurrency ? debt.amount - foundSeparationCurrency.totalSum : debt.amount;

                            if (!foundSeparationCurrency) {
                                debtTypeCurrency.push({
                                    id: debt.currency.id,
                                    name: debt.currency.name,
                                    totalSum: totalSum
                                });
                            }
                        }
                    });
                }


                if (debtTypeCurrency?.length) {
                    return(
                        debtTypeCurrency?.map((x, index) => {
                            if(x.totalSum) {
                                return (
                                    <div className={'fs-0'} key={index}>
                                        <SoftBadge className={'d-block text-end mb-1 flex-fill'} bg={x?.totalSum > 0 ? "danger" : "warning"}>
                                            {numeral.formats[numberFormat].format(x?.totalSum)}
                                            &nbsp;
                                            {x?.name}
                                        </SoftBadge>
                                    </div>
                                )
                            }
                        })
                    )
                }
                return ''
            }
        },
        {
            accessor: 'account.username',
            Header: t(lang, "Продавец"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex justify-content-end">
                        <span className="d-flex text-wrap">{original?.account?.name || original?.account?.username}</span>
                    </div>
                )
            }
        },
        {
            accessor: 'note',
            Header: t(lang, "Информация"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex justify-content-end" style={{maxWidth: "250px", minWidth: "250px"}}>
                        <span className="d-flex text-wrap">{original?.note}</span>
                    </div>
                )
            }
        },
        {
            accessor: 'created_at',
            Header: t(lang, "warehouse.operation.item.common.time"),
            headerProps: {
                className: "text-end"
            },
            cellProps: {
                className: "text-end"
            },
            Cell: ({row: {original}}) => {
                return (
                    <div className="d-flex flex-column">
                        <span>{dayjs(original?.date).format("YYYY-MM-DD")}</span>
                        <span>{dayjs(original?.date).format("HH:mm:ss")}</span>
                    </div>
                )
            }
        },
        {
            Header: '',
            id: "none",
            cellProps: {
                className: 'text-end'
            },
            Cell: ({row: {original}}) => (
                <div className="d-inline-flex">
                    <DownloadSaleDropdown className={'mx-1'} saleId={original?.id} saleNumber={original?.number}>
                        <FontAwesomeIcon className={'text-primary'} icon={faFilePdf}/>
                    </DownloadSaleDropdown>
                    <PrintSaleDropdown className={'mx-1'} saleId={original?.id}>
                        <FontAwesomeIcon className={'text-primary'} icon={faPrint}/>
                    </PrintSaleDropdown>
                    <Link to={`/warehouse/operation/sales/view/${original?.id}`}>
                        <IconButton
                            variant="falcon-info"
                            icon="eye"
                            title={t(lang, "warehouse.operation.item.common.show")}
                            className="mx-1"
                            size="sm"
                        />
                    </Link>
                    <UploadSaleToExcel className={'mx-1'} id={original?.id} number={original?.number}>
                        <FontAwesomeIcon className={'text-success'} icon={faFileExcel}/>
                    </UploadSaleToExcel>
                    <CardDropdown btnRevealClass="btn-reveal-sm">
                        <div className="py-2">
                            <Dropdown.Item as={Link} to={`/warehouse/operation/sales/update/${original?.id}`}
                                           className="text-warning">
                                <FontAwesomeIcon className="me-1" size="sm" icon={"pencil"}/>
                                <span>{t(lang, "warehouse.operation.item.common.edit")}</span>
                            </Dropdown.Item>
                            <Dropdown.Item as={Link} to={`/warehouse/operation/sales/update-excel/${original?.id}`}
                                           className="text-success">
                                <FontAwesomeIcon className="me-1" size="sm" icon={"pencil"}/>
                                <span>{t(lang, "Редактировать с Excel")}</span>
                            </Dropdown.Item>
                            <SaleDelete id={original?.id}/>
                            <Dropdown.Item as={Link} to={`/warehouse/operation/sales/view/${original?.id}`} className="text-info">
                                <FontAwesomeIcon className="me-1" size="sm" icon={"eye"}/>
                                <span>{t(lang, "warehouse.operation.item.common.show")}</span>
                            </Dropdown.Item>
                        </div>
                    </CardDropdown>
                </div>
            )
        }
    ];


    const onPageChange = (page) => dispatch(changeFilterOptionsAsync({...filters, page: page}))

    const onLimitChange = (limit) => dispatch(changeFilterOptionsAsync({...filters, limit: limit, page: 1}))

    return (
        <AdvanceTableWrapper
            columns={columns}
            data={saleItems}
            pagination
            perPage={1000}
            selection
            sortable
        >
            <Card className="mb-3">
                <Card.Header>
                    <SaleDatatableHeader predefinedFilters={predefinedFilters} loadSales={loadSales} table/>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        table
                        isLoading={loading}
                        headerClassName="bg-200 text-900 text-wrap align-middle"
                        rowClassName="align-middle white-space-nowrap border-bottom border-2"
                        tableProps={{

                            size: 'sm',
                            striped: true,
                            className: 'fs--1 mb-2 overflow-hidden',
                            style: {
                                minHeight: '270px'
                            }
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableCustomPagination
                        count={count}
                        limit={filters.limit}
                        page={filters.page}
                        onLimitChange={onLimitChange}
                        onPageChange={onPageChange}
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableWrapper>
    )
}

SaleDatatable.propTypes = {
    predefinedFilters: PropTypes.object,
};

SaleDatatable.defaultProps = {
    predefinedFilters: {},
};

export default SaleDatatable;
