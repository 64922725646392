import React, {Fragment, useEffect, useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import IconButton from "../../../../common/IconButton";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {FormProvider, useForm} from "react-hook-form";
import SoftBadge from "../../../../common/SoftBadge";
import dayjs from "dayjs";
import {
    changeFilterOptionsAsync,
    saleDeleteAsync,
    selectSaleFilterOptions
} from "../../../../../app/store/reducers/warehouse-operation/saleReducer";
import DatatableHeaderFilter from "../../components/form/DatatableHeaderFilter";
import UploadSalesToExcel from "../components/UploadSalesToExcel";
import UploadDriverSalePDF from "../components/UploadDriverSalePDF";
import BulkSaleDelete from "../components/BulkSaleDelete";

const SaleDatatableHeader = ({selectedRowIds, page, predefinedFilters, loadSales}) => {
    const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(true);
    const filter = useSelector(selectSaleFilterOptions)
    const dispatch = useDispatch()
    const lang = useSelector(selectLang)
    const t = Translate

    const selectedRowIndexArray = Object.keys(selectedRowIds);
    const selectedSales = selectedRowIndexArray.reduce((result, selectedIndex) => {
        result.push(page[selectedIndex]?.original);
        return result;
    }, [])

    const form = useForm({
        defaultValues: {
            date_time_start: filter?.date_time_start ? dayjs(filter?.date_time_start, "YYYY-MM-DD HH:mm").toDate() : null,
            date_time_end: filter?.date_time_end ? dayjs(filter?.date_time_end, "YYYY-MM-DD HH:mm").toDate() : null,
            contractor_id: filter?.contractor_id || null,
            employee_id: filter?.employee_id || null,
            operation_account_id: filter?.operation_account_id || null,
            is_approved: typeof (filter?.is_approved) === "boolean" ? filter.is_approved : null,
            warehouse_id: filter?.warehouse_id || null
        }
    })

    useEffect(async () => {
        if (predefinedFilters && Object.keys(predefinedFilters).length > 0) {
            let params = {...predefinedFilters};

            Object.keys(params).forEach(key => {
                form.setValue(key, params[key]);
            });
        }
    }, [predefinedFilters])


    const onSubmit = (formData) => {
        dispatch(changeFilterOptionsAsync({
            ...filter,
            date_time_start: formData?.date_time_start ? dayjs(formData?.date_time_start).format("YYYY-MM-DD HH:mm:ss") : null,
            date_time_end: formData?.date_time_end ? dayjs(formData?.date_time_end).format("YYYY-MM-DD HH:mm:ss") : null,
            is_approved: typeof (formData?.is_approved) === "boolean" ? formData.is_approved : null,
            contractor_id: formData?.contractor_id || null,
            employee_id: formData?.employee_id || null,
            operation_account_id: formData?.operation_account_id || null,
            warehouse_id: formData?.warehouse_id || null,
        }))
    }

    return (
        <>
            <Row>
                <Col xs={12}>
                    <FormProvider {...form}>
                        <Form id={'sale'} onSubmit={form.handleSubmit(onSubmit)}>
                            <DatatableHeaderFilter predefinedFilters={predefinedFilters}
                                                   name={t(lang, "warehouse.operation.item.common.list_sales")}
                                                   contractor isOpenFilterCollapse={isOpenFilterCollapse}/>
                        </Form>
                    </FormProvider>
                    <hr/>
                </Col>
                <Col xs={12} className="d-flex justify-content-between">
                    <div className="d-flex flex-column flex-sm-row justify-content-sm-around py-md-2">
                        <div className="ms-2">
                            <SoftBadge bg='success'
                                       className={'mt-2 mt-md-0'}>{t(lang, "warehouse.operation.item.common.status.confirmed")}</SoftBadge>
                            <SoftBadge bg='warning'
                                       className={'mt-2 mt-md-0 mx-md-1'}>{t(lang, "warehouse.operation.item.common.status.not_confirmed")}</SoftBadge>
                        </div>
                    </div>
                    <div>
                        {
                            Object.keys(selectedRowIds).length > 0 ? <Fragment>
                                <BulkSaleDelete
                                    selectedItems={selectedSales}
                                    actionRequest={saleDeleteAsync}
                                    loadItems={loadSales}
                                />
                            </Fragment> : <Fragment>
                                <UploadDriverSalePDF/>
                                <UploadSalesToExcel/>
                            </Fragment>
                        }
                        <IconButton
                            size="sm"
                            variant="falcon-primary"
                            icon="fa-plus"
                            as={Link}
                            to={'/warehouse/operation/sales/register'}
                        >{t(lang, "warehouse.operation.item.common.new_sales")}</IconButton>
                        <IconButton
                            size="sm"
                            variant="falcon-success"
                            icon="fa-plus"
                            className="mx-2"
                            as={Link}
                            to={'/warehouse/operation/sales/register-excel'}
                        >{t(lang, "Новая продажа с Excel")}</IconButton>
                        <IconButton
                            onClick={() => setIsOpenFilterCollapse(!isOpenFilterCollapse)}
                            variant={isOpenFilterCollapse ? "falcon-success" : "falcon-default"}
                            size="sm"
                            className="ms-2"
                            icon="filter"
                            transform="shrink-3"
                        >
                            <span
                                className="d-none d-sm-inline-block ms-1">{t(lang, "warehouse.operation.item.common.filter")}</span>
                        </IconButton>
                        <Button size={'sm'} form={'sale'} type="submit" variant="primary" className={'ms-2'}>
                            {t(lang, 'warehouse.operation.item.common.show')}
                        </Button>
                    </div>
                </Col>
            </Row>
        </>
    )
}


export default SaleDatatableHeader;
