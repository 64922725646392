import {useSelector} from "react-redux";
import {selectLang, selectNumberFormat, Translate} from "../../../../app/store/reducers/main/mainReducer";
import {Card, Col, Row, Tab, Tabs} from "react-bootstrap";
import SoftBadge from "../../../common/SoftBadge";
import IconButton from "../../../common/IconButton";
import {Link} from "react-router-dom";
import numeral from "numeral";
import {
    isApprovedCheckedWarehouseOperation, OperationTotalSum,
} from "../enum/warehouseOperationWrapper";
import {
    returnPurchaseApproveAsync,
    returnPurchasePaymentRegisterAsync, returnPurchasePaymentUpdateAsync
} from "../../../../app/store/reducers/warehouse-operation/returnPurchaseReducer";
import {toast} from "react-toastify";
import WarehouseOperationPayment from "../components/WarehouseOperationPayment";
import React, {useEffect, useState} from "react";
import WarehouseOperationWithoutPayment from "../components/WarehouseOperationWithoutPayment";
import {selectCurrency} from "../../../../app/store/reducers/currency/currencyReducer";
import {selectItems} from "../../../../app/store/reducers/item/itemReducer";
import WarehouseOperationCashBoxCard from "../components/WarehouseOperationCashBoxCard";
import WarehouseOperationDebtStateCard from "../components/WarehouseOperationDebtStateCard";
import {selectCardContractor} from "../../../../app/store/reducers/contractor/contractorReducer";
import WarehouseOperationDatatableTab from "../components/WarehouseOperationDatatableTab";
import WarehouseOperationFooterCurrencyTotalSum from "../components/WarehouseOperationFooterCurrencyTotalSum";
import WOWithoutDiscountPayment from "../components/WOWithoutDiscountPayment";
import UploadReturnPurchaseToExcel from "./excel/UploadReturnPurchaseToExcel";
import ReturnPurchasePrint from "./components/ReturnPurchasePrint";
import ReturnPurchaseDownloadPdf from "./components/ReturnPurchaseDownloadPdf";


const ReturnPurchase = ({returnPurchase}) => {
    const [activeTab, setActiveTab] = useState("all")
    const numberFormat = useSelector(selectNumberFormat);
    const contractor = useSelector(selectCardContractor)
    const currencies = useSelector(selectCurrency)
    const items = useSelector(selectItems)
    const lang = useSelector(selectLang)
    const t = Translate



    useEffect(() => {
        if (returnPurchase?.items?.filter(f => !f?.is_deleted).length > 0) {
            setActiveTab("all")
        } else {
            setActiveTab("deleted")
        }
    },[currencies])





    const onSubmitPay = async (pay) => {
        if (returnPurchase?.payment) {
            await returnPurchasePaymentUpdateAsync({returnPurchaseId: pay?.id, payment: pay?.payment})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
        }

        if (!returnPurchase?.payment && returnPurchase?.is_approved) {
            await returnPurchasePaymentRegisterAsync({returnPurchaseId: pay?.id, payment: pay?.payment})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })

        }

        if (!returnPurchase?.is_approved) {
            await returnPurchaseApproveAsync({returnPurchaseId: pay?.id, payment: pay?.payment || null})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
        }
    }

    return(
        <>
            <Card>
                <Card.Header>
                    <Row>
                        <Col xs={6}><h3 className="text-600">{t(lang, "warehouse.operation.item.common.time_return_purchase")} {returnPurchase?.date}</h3></Col>
                        <Col xs={6} className="text-end">
                            <span className="me-2">{t(lang, "warehouse.operation.item.common.sale_status")}: </span>
                            <SoftBadge bg={returnPurchase?.is_approved ? 'success' : 'warning'}>
                                {t(lang, isApprovedCheckedWarehouseOperation(returnPurchase?.is_approved))}
                            </SoftBadge>
                        </Col>
                        <Col xs={12}>
                            {currencies?.length > 0 && returnPurchase?.items?.filter(x => !x.is_deleted).length > 0 &&
                                // <WarehouseOperationPayment onSubmitPay={onSubmitPay} data={returnPurchase}/>
                                <WOWithoutDiscountPayment
                                    data={returnPurchase}
                                    onSubmitPay={onSubmitPay}
                                    isEditing={!!returnPurchase?.payment}
                                />
                            }
                            {!returnPurchase?.is_approved && returnPurchase?.items?.filter(x => !x.is_deleted).length > 0 &&
                                <WarehouseOperationWithoutPayment
                                    onClick={onSubmitPay}
                                    id={returnPurchase?.id}
                                />
                            }
                            <Link to={`../update/${returnPurchase?.id}`}>
                                <IconButton
                                    variant="falcon-warning"
                                    icon="edit"
                                    title={t(lang,"warehouse.operation.item.common.edit")}
                                    className="ms-3"
                                    size="sm"
                                >{t(lang,"warehouse.operation.item.common.edit")}</IconButton>
                            </Link>
                            <Link to={`../update-excel/${returnPurchase?.id}`}>
                                <IconButton
                                    variant="falcon-success"
                                    icon="edit"
                                    title={t(lang,"warehouse.operation.item.common.edit")}
                                    className="mx-2"
                                    size="sm"
                                >{t(lang,"Редактировать с Excel")}</IconButton>
                            </Link>
                            <UploadReturnPurchaseToExcel className="me-2 d-inline-block" size="sm" id={returnPurchase?.id} number={returnPurchase?.number} />
                            <Link to={`../register`}>
                                <IconButton
                                    variant="falcon-primary"
                                    icon="plus"
                                    title={t(lang,"Новый возвратов-приход")}
                                    className="ms-2"
                                    size="sm"
                                >{t(lang,"Новый возвратов-приход")}</IconButton>
                            </Link>

                            <ReturnPurchasePrint
                                returnPurchaseId={returnPurchase?.id}
                            />
                            <ReturnPurchaseDownloadPdf
                                returnPurchaseId={returnPurchase?.id}
                                number={returnPurchase?.number}
                            />
                        </Col>
                    </Row>
                </Card.Header>
            </Card>

            <Row>
                <Col xs={12} className="my-3">
                    <Card>
                        <Card.Header>
                            <h4 className="text-info mb-2">{t(lang, "warehouse.operation.item.common.return_purchase")}</h4>
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center fw-bold">
                                    <h5 className="me-2 fw-bold">{t(lang, "Имя поставщика")}:</h5> <span>{returnPurchase?.contractor?.name}</span>
                                </div>
                                <div className={"d-flex align-items-start flex-wrap fw-bold"}>
                                    <h5 className="d-flex justify-content-end text-nowrap fw-bold">{t(lang, "warehouse.operation.item.common.real_credit")}:</h5>
                                    <div className="fs-1 d-flex flex-wrap">
                                        {contractor?.debts?.length > 0 ? contractor?.debts.map((item, index) => {
                                            if (item.amount){
                                                return (
                                                    <div key={index} className="d-flex text-nowrap justify-content-end mx-2">
                                                        {numeral.formats[numberFormat].format(item.amount)} <span className="text-info ms-1">{item.currency.name}</span>
                                                    </div>
                                                )
                                            }
                                        }) : '0 сум'}
                                    </div>
                                </div>
                            </div>
                        </Card.Header>
                    </Card>
                </Col>
            </Row>

            {returnPurchase?.payment &&
                <>
                    <Row className="mb-3">
                        <Col xs={12} lg={6} className="m-1 m-lg-0">
                            <WarehouseOperationCashBoxCard cashBox={returnPurchase?.payment?.cash_box_states} />
                        </Col>
                        <Col xs={12} lg={6} className="m-1 m-lg-0">
                            <WarehouseOperationDebtStateCard debtState={returnPurchase?.payment?.debt_states} />
                        </Col>
                    </Row>
                    {returnPurchase?.payment?.notes &&
                        <Row>
                            <Col xs={12}>
                                <Card className="mb-3 p-3">
                                    <Card.Title className="text-center">
                                        {t(lang, "warehouse.operation.item.common.info")}
                                    </Card.Title>
                                    <Card.Text>
                                        {returnPurchase?.payment?.notes}
                                    </Card.Text>
                                </Card>
                            </Col>
                        </Row>
                    }
                </>
            }

            <Row>
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            {returnPurchase?.note &&
                                <Col xs={12}>
                                    <p className="fw-bold m-0">Описание: <span className="fw-medium">{returnPurchase?.note}</span> </p>
                                </Col>
                            }
                            <Tabs activeKey={activeTab} id="controlled-tab-example" className="d-flex justify-content-center fs-1" onSelect={(tab) => {
                                setActiveTab(tab)
                            }}>
                                {returnPurchase?.items?.filter(f => !f?.is_deleted).length > 0 &&
                                    <Tab eventKey="all" tabClassName="fs-1" title={t(lang, 'Списки')}>
                                        {activeTab === 'all' &&
                                            <WarehouseOperationDatatableTab
                                                dataItems={returnPurchase?.items?.filter(f => !f.is_deleted)}
                                            />
                                        }
                                    </Tab>
                                }
                                {returnPurchase?.items?.filter(f => f.is_deleted).length > 0 &&
                                    <Tab eventKey="deleted" tabClassName="fs-1" title={t(lang, 'Удалённые из списка')}>
                                        {activeTab === 'deleted' &&
                                            <WarehouseOperationDatatableTab
                                                dataItems={returnPurchase?.items?.filter(f => f.is_deleted)}
                                            />
                                        }
                                    </Tab>
                                }
                            </Tabs>
                            <Row className="g-0 d-flex flex-column align-items-end">
                                {activeTab === "all" &&
                                    <WarehouseOperationFooterCurrencyTotalSum
                                        netPrice={returnPurchase?.net_price}
                                        pay={returnPurchase?.payment}
                                    />
                                }
                                {activeTab === "deleted" &&
                                    <WarehouseOperationFooterCurrencyTotalSum
                                        netPriceType={"deleted"}
                                        netPrice={OperationTotalSum(returnPurchase?.items?.filter(f => f?.is_deleted))}
                                        pay={returnPurchase?.payment}
                                    />
                                }
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}


export default ReturnPurchase;
