export const CashBoxOperationTypePayment = 1
export const CashBoxOperationTypePayout = 2
export const CashBoxOperationTypeCashIn = 3
export const CashBoxOperationTypeCashOut = 4
export const CashBoxOperationTypeExpense = 5
export const CashBoxOperationTypeTransferIn = 6
export const CashBoxOperationTypeTransferOut = 7

export const CashBoxOperationLabelPayment = "cash_boxes.operation.type.payment"
export const CashBoxOperationLabelPayout = "cash_boxes.operation.type.payout"
export const CashBoxOperationLabelCashIn = "cash_boxes.operation.type.cash_in"
export const CashBoxOperationLabelCashOut = "cash_boxes.operation.type.cash_out"
export const CashBoxOperationLabelExpense = "cashboxes.expense.nav.label"

export const CashBoxOperationLabelTransferIn = "cash_boxes.operation.type.transfer_in"
export const CashBoxOperationLabelTransferOut = "cash_boxes.operation.type.transfer_out"


export const CashBoxOperationTypes = [
    {
        label: CashBoxOperationLabelPayment,
        value: CashBoxOperationTypePayment,
        variant: 'success'
    },
    {
        label: CashBoxOperationLabelPayout,
        value: CashBoxOperationTypePayout,
        variant: 'dark'
    },
    {
        label: CashBoxOperationLabelCashIn,
        value: CashBoxOperationTypeCashIn,
        variant: 'primary'
    },
    {
        label: CashBoxOperationLabelCashOut,
        value: CashBoxOperationTypeCashOut,
        variant: 'danger'
    },
    {
        label: CashBoxOperationLabelExpense,
        value: CashBoxOperationTypeExpense,
        variant: 'secondary'
    },
    {
        label: CashBoxOperationLabelTransferIn,
        value: CashBoxOperationTypeTransferIn,
        variant: 'info'
    },
    {
        label: CashBoxOperationLabelTransferOut,
        value: CashBoxOperationTypeTransferOut,
        variant: 'warning'
    },
]

export const currencyColor = (currencyName) => {
    switch (currencyName) {
        case 'UZS':
            return 'success'
        case 'USD':
            return 'primary'
        case 'RUB':
            return 'danger'
        default:
            return 'secondary'
    }
}
