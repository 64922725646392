import React, {useEffect, useState} from 'react';
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";
import {ndsOptions} from "../../enum/RoamingInvoiceWrapper";
import {Col, Form, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import FalconCloseButton from "./FalconCloseButton";

const VatRateLinkSelector = ({onChange, defaultVatRate, linkProps}) => {
    const t = Translate;
    const lang = useSelector(selectLang);
    const [show, setShow] = useState(false);
    const [taxRate, setTaxRate] = useState(null);

    useEffect(() => {
        if (taxRate?.value !== defaultVatRate)
            setTaxRate(ndsOptions.find(o => o.value === defaultVatRate));
    }, [defaultVatRate])

    const onChangeHandler = (option) => {
        setTaxRate(option);
        onChange(option.value);
        option && setShow(false);
    }

    const link = <span onClick={() => setShow(true)}
                       className="text-warning cursor-pointer fs--1"
                       {...linkProps}
    >
        {taxRate ? t(lang, taxRate.label) : t(lang, 'items.common.tax_rate_options.0')}
    </span>


    return (
        <>
            {taxRate && <OverlayTrigger overlay={<Tooltip>{t(lang, taxRate.label)}</Tooltip>}>
                {link}
            </OverlayTrigger>}
            {!taxRate && link}
            {show &&
                <Modal show={true}
                       onHide={() => setShow(false)}
                       size="xl"
                       aria-labelledby="contained-modal-title-vcenter"
                       centered
                >
                    <Modal.Header>
                        <Modal.Title>{t(lang, 'items.common.tax_rate_link_selector_modal.title')}</Modal.Title>
                        <FalconCloseButton onClick={() => setShow(false)}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>{t(lang, 'items.common.tax_rate')}</Form.Label>
                                    <ReactSelect options={ndsOptions}
                                                 hideSelectedOptions
                                                 classNamePrefix="select"
                                                 placeholder={t(lang, "items.common.select")}
                                                 getOptionLabel={option => t(lang, option.label)}
                                                 getOptionValue={option => option.value}
                                                 isSearchable={false}
                                                 defaultMenuIsOpen={true}
                                                 value={null}
                                                 onChange={onChangeHandler}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            }
        </>
    );
};

export default VatRateLinkSelector;
