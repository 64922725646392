import React, {useEffect} from 'react';
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import ReactSelect from "react-select";

const RoamingInvoiceFormBranchSelector = ({branches, defaultBranchCode, onChange, isWaybillEditing}) => {
    const t = Translate;
    const lang = useSelector(selectLang);

    useEffect(() => {
        isWaybillEditing && onChange(branches.find(b => b.code === defaultBranchCode) || null)
    }, [defaultBranchCode]);

    const formatOptionLabel = (option) => {
        return (
            <div>
                <span className="fw-bold me-2">{option.name}</span>
                <span>{option.code}</span>
            </div>
        )
    };

    return (
        <ReactSelect options={branches}
                     isClearable={true}
                     placeholder={t(lang, 'roaming.common.select.placeholder')}
                     getOptionLabel={option => `${option.name} ${option.code}`}
                     getOptionValue={option => option.code}
                     formatOptionLabel={formatOptionLabel}
                     value={branches.find(b => b.code === defaultBranchCode) || null}
                     onChange={!isWaybillEditing && onChange}
        />
    );
};

export default RoamingInvoiceFormBranchSelector;
