import React, {Fragment, useEffect, useState} from 'react';
import {Card, Col, Row, Table} from "react-bootstrap";
import SoftBadge from "../../../common/SoftBadge";
import numeral from "numeral";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDateDayJSFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../app/store/reducers/main/mainReducer";
import {selectMeasurements} from "../../../../app/store/reducers/roaming/roamingReducer";
import {selectActiveOrganization} from "../../../../app/store/reducers/auth/authReducer";
import RoamingInvoiceExecutorCancel from "./actions/RoamingInvoiceExecutorCancel";
import RoamingInvoiceCustomerAccept from "./actions/RoamingInvoiceCustomerAccept";
import RoamingInvoiceCustomerDecline from "./actions/RoamingInvoiceCustomerDecline";
import {Link} from "react-router-dom";
import roamingInvoiceWrapper, {
    markOptions,
    roamingInvoiceTypes, singleSidedInvoiceTypes,
    TypeAdditional,
    TypeAdditionalAmendOfExpenses,
    TypeCorrective,
    TypeCorrectiveAmendOfExpenses
} from "../../../../enum/RoamingInvoiceWrapper";
import dayjs from "dayjs";
import RoamingInvoiceSync from "./actions/RoamingInvoiceSync";
import EventBus from "../../../../app/eventbus/EventBus";
import {
    ROAMING_INVOICE_CUSTOMER_ACCEPT_FAILED,
    ROAMING_INVOICE_CUSTOMER_ACCEPT_SUCCESS,
    ROAMING_INVOICE_CUSTOMER_DECLINE_SUCCESS,
    ROAMING_INVOICE_EXECUTOR_CANCEL_FAILED,
    ROAMING_INVOICE_EXECUTOR_CANCEL_SUCCESS,
    ROAMING_INVOICE_PERSON_ACCEPT_FAILED,
    ROAMING_INVOICE_PERSON_ACCEPT_SUCCESS,
    ROAMING_INVOICE_PERSON_DECLINE_FAILED,
    ROAMING_INVOICE_PERSON_DECLINE_SUCCESS,
    ROAMING_INVOICE_SEND_FAILED,
    ROAMING_INVOICE_SYNC_FAILED, ROAMING_INVOICE_SYNC_HISTORIES_FAILED, ROAMING_INVOICE_SYNC_HISTORIES_SUCCESS,
    ROAMING_INVOICE_SYNC_SUCCESS
} from "../../../../app/eventbus/roaming/roamingInvoiceEvents";
import {ZERO_VAT_PRIVILEGE_TYPE} from "../../../../enum/roaming/LgotaTypes";
import WarningAlert from "../components/WarningAlert";
import RoamingInvoicePersonAccept from "./actions/RoamingInvoicePersonAccept";
import RoamingInvoicePersonDecline from "./actions/RoamingInvoicePersonDecline";
import RoamingInvoiceDraftDelete from "./actions/RoamingInvoiceDraftDelete";
import RoamingInvoicePrint from "./actions/RoamingInvoicePrint";
import RoamingInvoiceDraftSend from "./actions/RoamingInvoiceDraftSend";
import IconButton from "../../../common/IconButton";
import {faClone, faPencil, faPlus} from "@fortawesome/free-solid-svg-icons";
import {RoamingInvoiceViewPageWrapper} from "../../../../enum/roaming/RoamingInvoiceViewPageWrapper";
import SentHistoryActionSeal from "../components/history/SentHistoryActionSeal";
import CanceledHistoryActionSeal from "../components/history/CanceledHistoryActionSeal";
import DeclinedHistoryActionSeal from "../components/history/DeclinedHistoryActionSeal";
import AcceptedHistoryActionSeal from "../components/history/AcceptedHistoryActionSeal";
import checkPermission from "../../../../enum/Permission/CheckPermission";
import {
    PermissionRoamingInvoiceCreate,
    PermissionRoamingInvoiceUpdate
} from "../../../../enum/Permission/RoamingPermission";
import toastPermission from "../../../../enum/Permission/ToastPermission";
import RoamingInvoiceVatRegStatusBadge from "./form/RoamingInvoiceVatRegStatusBadge";
import RoamingInvoiceDownloadToPdf from "./actions/RoamingInvoiceDownloadToPdf";
import RoamingDraftInvoicePrint from "./actions/RoamingDraftInvoicePrint";
import RoamingDraftInvoiceDownloadToPdf from "./actions/RoamingDraftInvoiceDownloadToPdf";
import RoamingInvoiceSyncHistories from "./actions/RoamingInvoiceSyncHistories";

const RoamingInvoice = ({invoice}) => {
    const numberFormat = useSelector(selectNumberFormat);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const lang = useSelector(selectLang);
    const t = Translate;
    const measurements = useSelector(selectMeasurements);
    const activeOrganization = useSelector(selectActiveOrganization);
    const invoiceWrapper = new RoamingInvoiceViewPageWrapper(invoice);
    const dispatch = useDispatch()
    const [zeroVatPrivilegesInfo, setZeroVatPrivilegesInfo] = useState([])

    const canExecutorCancel = invoiceWrapper.canExecutorCancel(activeOrganization.pinfl || activeOrganization.inn);
    const canCustomerAccept = invoiceWrapper.canCustomerAccept(activeOrganization.pinfl || activeOrganization.inn);
    const canCustomerDecline = invoiceWrapper.canCustomerDecline(activeOrganization.pinfl || activeOrganization.inn);
    const canPersonAccept = invoiceWrapper.canPersonAccept(activeOrganization.pinfl || activeOrganization.inn);
    const canPersonDecline = invoiceWrapper.canPersonDecline(activeOrganization.pinfl || activeOrganization.inn);
    const canSync = invoiceWrapper.canSync();
    const canCopy = invoiceWrapper.canCopy(activeOrganization.pinfl || activeOrganization.inn)
    const canEditDraft = invoiceWrapper.canEditDraft(activeOrganization.pinfl || activeOrganization.inn);
    const canCreateAdditionalInvoice = invoiceWrapper.canCreateAdditionalInvoice(activeOrganization.pinfl || activeOrganization.inn);
    const canCreateCorrectiveInvoice = invoiceWrapper.canCreateCorrectiveInvoice(activeOrganization.pinfl || activeOrganization.inn);
    const canCreateAdditionalAmendOfExpensesInvoice = invoiceWrapper.canCreateAdditionalAmendOfExpensesInvoice(activeOrganization.pinfl || activeOrganization.inn);
    const canCreateCorrectiveAmendOfExpensesInvoice = invoiceWrapper.canCreateCorrectiveAmendOfExpensesInvoice(activeOrganization.pinfl || activeOrganization.inn);

    const getMeasure = id => measurements.find(m => m.measureId === id) || null;
    const getMark = type => markOptions.find(m => m.value === type) || null;

    const isEmpowered = invoiceWrapper.isEmpowered();
    const isWaybilled = invoiceWrapper.isWaybilled();
    const isAdditionalOrCorrective = invoiceWrapper.isAdditionalOrCorrective();
    const isSingleSide = invoiceWrapper.isSingleSide();
    const hasMedical = invoiceWrapper.hasMedical();
    const hasExcise = invoiceWrapper.hasExcise();
    const hasMark = invoiceWrapper.hasMark();
    const hasCommittent = invoiceWrapper.hasCommittent();
    const hasExchange = invoiceWrapper.hasExchange();
    const isReportHiddenForCommittent = invoiceWrapper.isReportHiddenForCommittent();
    const isDraft = invoiceWrapper.isDraft();
    const isDeclined = invoiceWrapper.isStatusDeclinedByCustomerOrPerson();
    const isExecutor = invoiceWrapper.isExecutor(activeOrganization?.person_num || activeOrganization?.inn);

    const excise = invoice.items.reduce((acc, item) => acc + item.excise_value, 0) || 0;
    const medical = invoice.items.reduce((acc, item) => acc + item.base_price, 0) || 0;

    const roamingInvoiceCreate = () => {
        return dispatch(checkPermission(PermissionRoamingInvoiceCreate))
    }

    const [eventMessage, setEventMessage] = useState(null);

    useEffect(() => {
        //success

        const onExecutorCancelSuccessHandler = EventBus.on(ROAMING_INVOICE_EXECUTOR_CANCEL_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
                text: t(lang, 'roaming.invoice.alert.invoice_cancel_success')
            });
        })
        const onCustomerAcceptSuccessHandler = EventBus.on(ROAMING_INVOICE_CUSTOMER_ACCEPT_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
                text: t(lang, 'roaming.invoice.alert.invoice_accept_success')
            });
        })
        const onCustomerDeclineSuccessHandler = EventBus.on(ROAMING_INVOICE_CUSTOMER_DECLINE_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
                text: t(lang, 'roaming.invoice.alert.invoice_decline_success')
            });
        })
        const onPersonAcceptSuccessHandler = EventBus.on(ROAMING_INVOICE_PERSON_ACCEPT_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
                text: t(lang, 'roaming.invoice.alert.invoice_accept_success')
            });
        })
        const onPersonDeclineSuccessHandler = EventBus.on(ROAMING_INVOICE_PERSON_DECLINE_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.empowerment.alert.success_event_title'),
                text: t(lang, 'roaming.invoice.alert.invoice_decline_success')
            });
        })
        const onInvoiceSyncSuccessHandler = EventBus.on(ROAMING_INVOICE_SYNC_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
                text: t(lang, 'roaming.invoice.alert.invoice_sync_success')
            });
        })

        const onInvoiceSyncHistoriesSuccessHandler = EventBus.on(ROAMING_INVOICE_SYNC_HISTORIES_SUCCESS, () => {
            setEventMessage({
                variant: 'success',
                title: t(lang, 'roaming.invoice.alert.success_event_title'),
                text: t(lang, 'roaming.invoice.alert.invoice_sync_success')
            });
        })


        //fail

        const onExecutorCancelFailedHandler = EventBus.on(ROAMING_INVOICE_EXECUTOR_CANCEL_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error.message
            });
        })
        const onCustomerAcceptFailedHandler = EventBus.on(ROAMING_INVOICE_CUSTOMER_ACCEPT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error.message
            });
        })
        const onCustomerDeclineFailedHandler = EventBus.on(ROAMING_INVOICE_PERSON_DECLINE_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error.message
            });
        })
        const onPersonAcceptFailedHandler = EventBus.on(ROAMING_INVOICE_PERSON_ACCEPT_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error.message
            });
        })
        const onPersonDeclineFailedHandler = EventBus.on(ROAMING_INVOICE_PERSON_DECLINE_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error.message
            })
        })
        const onInvoiceSyncFailedHandler = EventBus.on(ROAMING_INVOICE_SYNC_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error.message
            })
        })

        const onInvoiceSyncHistoriesFailedHandler = EventBus.on(ROAMING_INVOICE_SYNC_HISTORIES_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error.message
            })
        })
        const onDraftSendFailedHandler = EventBus.on(ROAMING_INVOICE_SEND_FAILED, (error) => {
            setEventMessage({
                variant: 'danger',
                title: t(lang, 'roaming.invoice.alert.failed_event_title'),
                text: error.message
            })
        })

        return () => {
            EventBus.remove(ROAMING_INVOICE_EXECUTOR_CANCEL_SUCCESS, onExecutorCancelSuccessHandler)
            EventBus.remove(ROAMING_INVOICE_CUSTOMER_ACCEPT_SUCCESS, onCustomerAcceptSuccessHandler)
            EventBus.remove(ROAMING_INVOICE_CUSTOMER_DECLINE_SUCCESS, onCustomerDeclineSuccessHandler)
            EventBus.remove(ROAMING_INVOICE_PERSON_ACCEPT_SUCCESS, onPersonAcceptSuccessHandler)
            EventBus.remove(ROAMING_INVOICE_PERSON_DECLINE_SUCCESS, onPersonDeclineSuccessHandler)
            EventBus.remove(ROAMING_INVOICE_SYNC_SUCCESS, onInvoiceSyncSuccessHandler)
            EventBus.remove(ROAMING_INVOICE_SYNC_HISTORIES_SUCCESS, onInvoiceSyncHistoriesSuccessHandler)

            EventBus.remove(ROAMING_INVOICE_EXECUTOR_CANCEL_FAILED, onExecutorCancelFailedHandler)
            EventBus.remove(ROAMING_INVOICE_CUSTOMER_ACCEPT_FAILED, onCustomerAcceptFailedHandler)
            EventBus.remove(ROAMING_INVOICE_PERSON_DECLINE_FAILED, onCustomerDeclineFailedHandler)
            EventBus.remove(ROAMING_INVOICE_PERSON_ACCEPT_FAILED, onPersonAcceptFailedHandler)
            EventBus.remove(ROAMING_INVOICE_PERSON_DECLINE_FAILED, onPersonDeclineFailedHandler)
            EventBus.remove(ROAMING_INVOICE_SYNC_FAILED, onInvoiceSyncFailedHandler)
            EventBus.remove(ROAMING_INVOICE_SYNC_HISTORIES_FAILED, onInvoiceSyncHistoriesFailedHandler)
            EventBus.remove(ROAMING_INVOICE_SEND_FAILED, onDraftSendFailedHandler)
        }

    }, [])

    useEffect(() => {
        const privileges = []

        if (invoice) {
            invoice.items.forEach((item) => {
                if (item.lgota_id) {
                    let privilege = privileges.find(x => {
                        return x.id === item.lgota_id && x.lgota_type === item.lgota_type
                    }) || null

                    if (!privilege) {
                        privilege = {
                            id: item.lgota_id,
                            lgota_name: item.lgota_name,
                            lgota_type: item.lgota_type,
                            lgota_vat_sum: 0,
                            lgota_vat_total_sum: 0,
                        }

                        privileges.push(privilege)
                    }

                    privilege.lgota_vat_sum += item.lgota_vat_sum
                    privilege.lgota_vat_total_sum += item.total
                }
            });
        }

        setZeroVatPrivilegesInfo(privileges.filter(x => x.lgota_type === ZERO_VAT_PRIVILEGE_TYPE))
    }, [invoice])

    const isZeroVatPrivilegesExists = zeroVatPrivilegesInfo.length > 0
    const facturaType = roamingInvoiceTypes.find((type) => type.value === invoice?.factura_type)

    return (
        <>
            {eventMessage && <WarningAlert text={eventMessage.text} title={eventMessage.title} variant={eventMessage.variant}/>}

            {!eventMessage && invoice?.roaming_error && <WarningAlert text={invoice?.roaming_error.message} title={'Ошибка от роуминга'} variant={'danger'}/>}

            <Card className="p-3">
                <Row>
                    <h3 className="text-600 ms-2 col-md-8">{t(lang, "edi.common.created.time")}: {invoice.created_at}</h3>
                    <Col className="text-end">
                        <strong className="me-2 fs--1">{t(lang, "roaming.common.status")}:</strong>
                        <SoftBadge pill bg={invoiceWrapper.getStatusVariant(activeOrganization.inn)}
                                   className="fs--2">
                            {t(lang, invoiceWrapper.getStatusText(activeOrganization.inn))}
                        </SoftBadge>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className={'d-flex align-items-center gap-2'}>
                        {isDraft && <>
                            <RoamingDraftInvoicePrint size="sm" variant="falcon-default" id={invoice.id}/>
                            <RoamingDraftInvoiceDownloadToPdf  invoice={invoice}/>
                        </>}

                        {isDraft && <RoamingInvoiceDraftDelete id={invoice.id} hasTitle size="sm" />}
                        {isDraft && <RoamingInvoiceDraftSend invoice={invoice} size="sm"/>}
                        {!isDraft && <>
                            <RoamingInvoicePrint size="sm" variant="falcon-default"  id={invoice.id}/>
                            <RoamingInvoiceDownloadToPdf invoice={invoice} />
                            <RoamingInvoiceSyncHistories id={invoice.id} />
                        </>}
                        {canSync && <RoamingInvoiceSync id={invoice.id} />}
                        {canExecutorCancel && <RoamingInvoiceExecutorCancel id={invoice.id}/>}
                        {canCustomerAccept && <RoamingInvoiceCustomerAccept id={invoice.id} />}
                        {canCustomerDecline && <RoamingInvoiceCustomerDecline id={invoice.id}/>}
                        {canPersonAccept && <RoamingInvoicePersonAccept id={invoice.id} />}
                        {canPersonDecline && <RoamingInvoicePersonDecline id={invoice.id}/>}
                        {canEditDraft &&
                            <IconButton size='sm'
                                        to={`/roaming/invoice/send?draft_invoice_id=${invoice.id}&is_edit=true`}
                                        as={dispatch(checkPermission(PermissionRoamingInvoiceUpdate)) ? Link : 'div'}
                                        onClick={() => !dispatch(checkPermission(PermissionRoamingInvoiceUpdate)) && dispatch(toastPermission())}
                                        variant='falcon-warning'
                                        icon={faPencil}

                            >
                                {t(lang, 'roaming.common.edit')}
                            </IconButton>
                        }
                        {canCopy && isDraft &&
                            <Link to={`/roaming/invoice/send?draft_invoice_id=${invoice.id}`}>
                                <IconButton size='sm'
                                            variant='falcon-primary'
                                            icon={faClone}

                                >
                                    {t(lang, 'roaming.invoice.copy')}
                                </IconButton>
                            </Link>}


                        {canCopy && !isDraft &&
                            <Link to={`/roaming/invoice/send?invoice_id=${invoice.id}`}>
                                <IconButton size='sm'
                                            variant='falcon-primary'
                                            icon={faClone}

                                >
                                    {t(lang, 'roaming.invoice.copy')}
                                </IconButton>
                            </Link>}
                        {canCreateAdditionalInvoice &&
                            <IconButton size='sm'
                                        to={`/roaming/invoice/send?invoice_id=${invoice.id}&type=${TypeAdditional}`}
                                        as={roamingInvoiceCreate() ? Link : 'div'}
                                        onClick={() => !roamingInvoiceCreate() && dispatch(toastPermission())}
                                        variant='falcon-success'
                                        icon={faPlus}

                            >
                                {t(lang, 'roaming.invoice.send_form.type.option.1')}
                            </IconButton>
                        }
                        {canCreateCorrectiveInvoice &&
                            <IconButton size='sm'
                                        to={`/roaming/invoice/send?invoice_id=${invoice.id}&type=${TypeCorrective}`}
                                        as={roamingInvoiceCreate() ? Link : 'div'}
                                        onClick={() => !roamingInvoiceCreate() && dispatch(toastPermission())}
                                        variant='falcon-success'
                                        icon={faPlus}

                            >
                                {t(lang, 'roaming.invoice.send_form.type.option.4')}
                            </IconButton>
                        }
                        {canCreateAdditionalAmendOfExpensesInvoice &&

                            <IconButton size='sm'
                                        to={`/roaming/invoice/send?invoice_id=${invoice.id}&type=${TypeAdditionalAmendOfExpenses}`}
                                        as={roamingInvoiceCreate() ? Link : 'div'}
                                        onClick={() => !roamingInvoiceCreate() && dispatch(toastPermission())}
                                        variant='falcon-success'
                                        icon={faPlus}

                            >
                                {t(lang, 'roaming.invoice.send_form.type.option.6')}
                            </IconButton>
                        }

                        {canCreateCorrectiveAmendOfExpensesInvoice &&
                            <IconButton size='sm'
                                        to={`/roaming/invoice/send?invoice_id=${invoice.id}&type=${TypeCorrectiveAmendOfExpenses}`}
                                        as={roamingInvoiceCreate() ? Link : 'div'}
                                        onClick={() => !roamingInvoiceCreate() && dispatch(toastPermission())}
                                        variant='falcon-success'
                                        icon={faPlus}
                            >
                                {t(lang, 'roaming.invoice.send_form.type.option.5')}
                            </IconButton>
                        }
                        {isExecutor && <Link to={`/roaming/waybill-v2/send?invoice_id=${invoice.id}`}>
                            <IconButton size="sm"
                                        variant="falcon-info"
                                        icon={faPlus}
                            >
                                {t(lang, 'roaming.waybill.v2.send.shipment')}
                            </IconButton>
                        </Link>}
                    </Col>
                </Row>
            </Card>

            <Card className="my-3 p-3">
                <Row className="d-flex flex-lg-row flex-column">
                    <Col xs={12} lg={5} className="d-flex flex-column">
                        <h5 className="fw-bold mb-1">{t(lang, 'roaming.common.executor')}</h5>
                        <span><b>{t(lang, "edi.common.company")}:</b> {invoice.executor.name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {invoice.executor.inn}</span>
                    </Col>

                    {!isSingleSide && <Col xs={12} lg={5} className="d-flex flex-column my-3 my-lg-0">
                        <h5 className="fw-bold mb-1">{t(lang, 'roaming.common.customer')}</h5>
                        <span><b>{t(lang, "edi.common.company")}:</b> {invoice.customer.name}</span>
                        <span><b>{t(lang, 'edi.common.label.inn')}:</b> {invoice.customer.inn}</span>
                    </Col>}

                    {/*<Col xs={3} lg={2} className="border border-black">*/}
                    {/*    QR CODE*/}
                    {/*</Col>*/}
                </Row>
            </Card>

            <Card>
                <Card.Header>
                    <Row
                        className="d-flex flex-column flex-lg-row align-items-md-center justify-content-lg-between">
                        <Col xs={12} md={8} lg={5}
                             className="d-flex flex-column justify-content-center justify-content-lg-start">
                            <h5 className="text-info">{t(lang, `${facturaType?.label}`)}</h5>
                            <h5 className="p-0 mt-2">
                                <b className="pe-1">{t(lang, "edi.common.id")}:</b>
                                {invoice.id}
                            </h5>
                        </Col>
                        <Col xs={12} md={8} lg={4}>
                            {isDeclined &&
                                <div className="alert-danger mt-0 mt-lg-4 text-center">
                                    <span className="fw-bold me-2">{t(lang, "roaming.invoice.comment")}:</span>
                                    <strong>{invoice.notes}</strong>
                                </div>
                            }
                        </Col>
                    </Row>


                    <Row className="my-3 d-flex flex-column">
                        <h3 className="text-center">{t(lang, "roaming.common.invoice")}</h3>
                        <Col className="d-flex flex-column align-items-center my-2">
                            <div className="fs--1">
                                <span className="me-2 fw-bold">{t(lang, "roaming.invoice.number")}:</span>
                                <span>{invoice.info.number}</span>
                            </div>
                            <div className="fs--1">
                                <span className="me-2 fw-bold">{t(lang, "roaming.invoice.date")}:</span>
                                <span>{dayjs(invoice.info.date, "YYYY-MM-DD").format(dateFormat)}</span>
                            </div>
                            <div className="fs--1">
                                <span className="me-2 fw-bold">{t(lang, "roaming.invoice.updated_at")}:</span>
                                <span>{dayjs(invoice.updated_at, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")}</span>
                            </div>
                        </Col>
                        <Col className="d-flex flex-column flex-lg-row align-items-center justify-content-between">
                            {isAdditionalOrCorrective &&
                                <Col xs={12} lg={4} className="d-flex flex-column align-items-center">
                                    <h5>{t(lang, "roaming.invoice.old_invoice")}:</h5>
                                    <div className="fs--1">
                                        <strong className="me-2">{t(lang, "roaming.invoice.number")}:</strong>
                                        {isDraft &&
                                            <Link to={`../${invoice.old_invoice.id}`}>
                                                <span className="fw-bold">{invoice.old_invoice.number}</span>
                                            </Link>
                                        }
                                        {!isDraft &&
                                            <Link to={`${invoice.old_invoice.id}`}>
                                                <span className="fw-bold">{invoice.old_invoice.number}</span>
                                            </Link>
                                        }
                                    </div>
                                    <div className="fs--1">
                                        <strong className="me-2">{t(lang, "roaming.invoice.old_invoice.date")}:</strong>
                                        <span
                                            className="fw-bold">{dayjs(invoice.old_invoice.date, "YYYY-MM-DD").format(dateFormat)}</span>
                                    </div>
                                </Col>
                            }

                            {isEmpowered &&
                                <Col xs={12} lg={4} className="mt-3 mt-lg-0 d-flex flex-column align-items-center">
                                    <h5>{t(lang, "roaming.common.empowerment")}:</h5>
                                    <div className="fs--1">
                                        <strong
                                            className="me-2">{t(lang, "roaming.common.empowerment.number")}:</strong>
                                        <span className="fw-bold">{invoice.empowerment.number}</span>
                                    </div>
                                    <div className="fs--1">
                                        <strong className="me-2">{t(lang, "roaming.common.empowerment.date")}:</strong>
                                        <span
                                            className="fw-bold">{dayjs(invoice.empowerment.date, "YYYY-MM-DD").format(dateFormat)}</span>
                                    </div>
                                </Col>
                            }
                            <Col xs={12} lg={4} className="mt-3 mt-lg-0 d-flex flex-column align-items-center">
                                <h5>{t(lang, "roaming.common.contract")}:</h5>
                                <div className="fs--1">
                                    <strong className="me-2">{t(lang, "roaming.contract.number")}:</strong>
                                    <span className="fw-bold">{invoice.contract.number}</span>
                                </div>
                                <div className="fs--1">
                                    <strong className="me-2">{t(lang, "roaming.contract.date")}:</strong>
                                    <span
                                        className="fw-bold">{dayjs(invoice.contract.date, "YYYY-MM-DD").format(dateFormat)}</span>
                                </div>
                            </Col>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} lg={6} className="fs--1">
                            <div className="row">
                                <span
                                    className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.common.executor')}</span>
                                <span className="col-8 mb-1 ps-0">
                                    <span>{invoice.executor.name}</span>
                                    {(invoice.executor.branch.code && invoice.executor.branch.name) && <span
                                        className="ms-2">({invoice.executor.branch.code} - {invoice.executor.branch.name})</span>}
                                    <hr className="m-0" style={{height: "2px"}}/>
                                </span>
                            </div>
                            <div className="row">
                                <span className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.common.inn')}</span>
                                <span className="col-8 mb-1 ps-0">{invoice.executor.inn}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.common.address')}</span>
                                <span className="col-8 mb-1 ps-0">{invoice.executor.address}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span className="col-4 mb-1 pe-0 fw-bold">{t(lang, "roaming.common.director")}</span>
                                <span className="col-8 mb-1 ps-0">{invoice.executor.director}
                                    <hr className={invoice.executor.director ? "m-0" : "mb-0"} style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span className="col-4 mb-1 pe-0 fw-bold">{t(lang, "roaming.common.accountant")}</span>
                                <span className="col-8 mb-1 ps-0">{invoice.executor.accountant}
                                    <hr className={invoice.executor.accountant ? "m-0" : "mb-0"}
                                        style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.common.bank')}</span>
                                <span className="col-8 mb-1 ps-0">{invoice.executor.bank_name}
                                    <hr className={invoice.executor.bank_name ? "m-0" : "mb-0"}
                                        style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span
                                    className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.common.bank_account')}</span>
                                <span className="col-8 mb-1 ps-0">{invoice.executor.account}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.common.bank_mfo')}</span>
                                <span className="col-8 mb-1 ps-0">{invoice.executor.mfo}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.common.nds_code')}</span>
                                <span className="col-8 mb-1 ps-0">{invoice.executor.nds_registration_code}
                                    <hr className="m-0" style={{height: "2px"}}/></span>
                            </div>
                            <div className="row">
                                <span className="col-4 mb-1 pe-0 fw-bold">{t(lang, "roaming.common.nds_status")}</span>
                                <span className="col-8 mb-1 ps-0">
                                    <RoamingInvoiceVatRegStatusBadge identifier={invoice.executor.inn}
                                                                     vatRegStatus={invoice.executor.nds_status}
                                                                     vatRegCode={invoice.executor.nds_registration_code}
                                    />
                                    <hr className="m-0" style={{height: "2px"}}/>
                                </span>
                            </div>
                        </Col>
                        {isSingleSide ?
                            <Col xs={12} lg={6} className="my-3 fs--1 my-lg-0">
                                <Row>
                                    <Col xs={12} lg={6} xl={3} className="mx-auto">
                                        <div className='border-300 my-2 p-2 bg-100 text-secondary' style={{borderRadius: '10px', border: '10px solid'}}>
                                            <p className={`fs-1 fw-semi-bold text-center text-uppercase my-3`}>{t(lang, singleSidedInvoiceTypes.find(o => o.value === parseInt(invoice.single_sided_type))?.label)}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            :
                            <Col xs={12} lg={6} className="my-3 fs--1 my-lg-0">
                                <div className="row">
                                    <span
                                        className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.common.customer')}</span>
                                    <span className="col-8 mb-1 ps-0">
                                    <span>{invoice.customer.name}</span>
                                        {(invoice.customer.branch.code && invoice.customer.branch.name) && <span
                                            className="ms-2">({invoice.customer.branch.code} - {invoice.customer.branch.name})</span>}
                                        <hr className="m-0" style={{height: "2px"}}/>
                                </span>
                                </div>
                                <div className="row">
                                    <span className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.common.inn')}</span>
                                    <span className="col-8 mb-1 ps-0">{invoice.customer.inn}
                                        <hr className="m-0" style={{height: "2px"}}/></span>
                                </div>
                                <div className="row">
                                    <span className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.common.address')}</span>
                                    <span className="col-8 mb-1 ps-0">{invoice.customer.address}
                                        <hr className="m-0" style={{height: "2px"}}/></span>
                                </div>
                                <div className="row">
                                    <span
                                        className="col-4 mb-1 pe-0 fw-bold">{t(lang, "roaming.common.director")}</span>
                                    <span className="col-8 mb-1 ps-0">{invoice.customer.director}
                                        <hr className={invoice.customer.director ? "m-0" : "mb-0"}
                                            style={{height: "2px"}}/></span>
                                </div>
                                <div className="row">
                                    <span
                                        className="col-4 mb-1 pe-0 fw-bold">{t(lang, "roaming.common.accountant")}</span>
                                    <span className="col-8 mb-1 ps-0">{invoice.customer.accountant}
                                        <hr className={invoice.customer.accountant ? "m-0" : "mb-0"}
                                            style={{height: "2px"}}/></span>
                                </div>
                                <div className="row">
                                    <span
                                        className="col-4 fs-1 mb-1 pe-0 fw-bold">{t(lang, 'roaming.common.bank')}</span>
                                    <span className="col-8 mb-1 ps-0">{invoice.customer.bank_name}
                                        <hr className={invoice.customer.bank_name ? "m-0" : "mb-0"}
                                            style={{height: "2px"}}/></span>
                                </div>
                                <div className="row">
                                    <span
                                        className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.common.bank_account')}</span>
                                    <span className="col-8 mb-1 ps-0">{invoice.customer.account}
                                        <hr className="m-0" style={{height: "2px"}}/></span>
                                </div>
                                <div className="row">
                                    <span
                                        className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.common.bank_mfo')}</span>
                                    <span className="col-8 mb-1 ps-0">{invoice.customer.mfo}
                                        <hr className="m-0" style={{height: "2px"}}/></span>
                                </div>
                                <div className="row">
                                    <span
                                        className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.common.nds_code')}</span>
                                    <span className="col-8 mb-1 ps-0">{invoice.customer.nds_registration_code}
                                        <hr className="m-0" style={{height: "2px"}}/></span>
                                </div>
                                <div className="row">
                                    <span
                                        className="col-4 mb-1 pe-0 fw-bold">{t(lang, "roaming.common.nds_status")}</span>
                                    <span className="col-8 mb-1 ps-0">
                                    <RoamingInvoiceVatRegStatusBadge identifier={invoice.customer.inn}
                                                                     vatRegStatus={invoice.customer.nds_status}
                                                                     vatRegCode={invoice.customer.nds_registration_code}
                                    />
                                    <hr className="m-0" style={{height: "2px"}}/>
                                </span>
                                </div>
                                {hasExchange &&
                                    <div className="row">
                                        <span className="col-4 mb-1 pe-0 fw-bold">{t(lang, 'roaming.invoice.exchange')}</span>
                                        <span className="col-8 mb-1 ps-0">
                                            <b>{invoice.lot_id}</b>
                                            <hr className="m-0" style={{height: "2px"}}/>
                                        </span>
                                    </div>
                                }
                            </Col>
                        }
                    </Row>
                </Card.Header>

                <Card.Body>
                    <Row>
                        <h5 className="my-3 text-center">{t(lang, "roaming.common.items.title")}</h5>
                        {hasMark && <Table responsive bordered className="mt-3 border-bottom fs--1 border-500">
                            <thead className="bg-200 text-dark">
                            <tr>
                                <th rowSpan={2} className="text-center">№</th>
                                <th rowSpan={2} className="text-center">{t(lang, "roaming.invoice.items.product")}</th>
                                <th rowSpan={2}
                                    className="text-center">{t(lang, "roaming.invoice.items.mark_category")}</th>
                                <th colSpan={3}
                                    className="text-center">{t(lang, "roaming.invoice.items.mark_code")}</th>
                            </tr>
                            <tr>
                                <th className="align-middle text-center">{t(lang, "roaming.invoice.items.package.0")}</th>
                                <th className="align-middle text-center">{t(lang, "roaming.invoice.items.package.1")}</th>
                                <th className="align-middle text-center">{t(lang, "roaming.invoice.items.package.2")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {invoice.items.map((item, index) => (
                                <tr key={item.ordering_number}>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="align-middle text-nowrap fw-semi-bold">{item.name}</td>
                                    <td className="text-center">{t(lang, getMark(item.marking?.product_type)?.label)}</td>
                                    <td>
                                        {item.marking?.kiz && <ol>
                                            {item.marking?.kiz?.map(mark => (
                                                <li key={mark}>{mark}</li>
                                            ))}
                                        </ol>}
                                    </td>
                                    <td>
                                        {
                                            item.marking?.nom_upak && <ol>
                                                {item.marking?.nom_upak?.map(mark => (
                                                    <li key={mark}>{mark}</li>
                                                ))}
                                            </ol>
                                        }
                                    </td>
                                    <td>
                                        {
                                            item.marking?.ident_trans_upak && <ol>
                                                {item.marking?.ident_trans_upak?.map(mark => (
                                                    <li key={mark}>{mark}</li>
                                                ))}
                                            </ol>
                                        }
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>}
                        <Table bordered responsive className="border-bottom fs--1 border-500">
                            <thead className="text-dark bg-200">
                            <tr>
                                <th rowSpan={3} className="text-center">№</th>
                                <th rowSpan={2} className="text-center">{t(lang, "roaming.invoice.items.product")}</th>
                                <th rowSpan={2} className="text-center">{t(lang, "roaming.invoice.items.catalog")}</th>
                                {hasMedical && <th rowSpan={2}
                                                   className="text-center">{t(lang, "roaming.invoice.items.serial")}</th>}
                                <th rowSpan={2}
                                    className="text-center">{t(lang, "roaming.invoice.items.measurement")}</th>
                                {hasMedical && <th rowSpan={2}
                                                   className="text-center">{t(lang, "roaming.invoice.items.medical.value")}</th>}
                                {hasMedical && <th rowSpan={2}
                                                   className="text-center">{t(lang, "roaming.invoice.items.medical.rate")}</th>}
                                <th rowSpan={2} className="text-center">{t(lang, "roaming.invoice.items.quantity")}</th>
                                <th rowSpan={2} className="text-center">{t(lang, "roaming.invoice.items.price")}</th>
                                {hasExcise && <th colSpan={2}
                                                  className="align-middle text-center">{t(lang, "roaming.invoice.items.excise")}</th>}
                                <th rowSpan={2} className="text-center">{t(lang, "roaming.invoice.items.total")}</th>
                                <th colSpan={2}
                                    className="align-middle text-center">{t(lang, "roaming.invoice.items.nds")}</th>
                                <th rowSpan={2}
                                    className="text-center">{t(lang, "roaming.invoice.items.total_with_nds")}</th>
                                <th rowSpan={2}
                                    className="text-center">{t(lang, "roaming.common.items.origin")}</th>
                            </tr>
                            <tr>
                                {hasExcise && <>
                                    <th className="align-middle">{t(lang, "roaming.invoice.items.rate")}</th>
                                    <th className="align-middle">{t(lang, "roaming.invoice.items.value")}</th>
                                </>}
                                <th className="align-middle">{t(lang, "roaming.invoice.items.rate")}</th>
                                <th className="align-middle">{t(lang, "roaming.invoice.items.value")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {invoice.items.map((item, index) => (
                                <tr key={item.ordering_number}>
                                    <td>{index + 1}</td>
                                    <td className="align-middle text-nowrap fw-semi-bold">
                                        <div>{item.name}</div>
                                        {hasCommittent &&
                                            <div className="mt-3 d-flex flex-column border-top border-400">
                                                <div className="text-truncate" style={{maxWidth: '300px'}}>
                                                    <span
                                                        className="fw-bold me-2">{t(lang, 'roaming.invoice.committent')}:</span>
                                                    <span>{item.committent_inn}</span>
                                                    <b className="ms-2">{item.committent_name}</b>
                                                </div>
                                                <div>
                                                    <span
                                                        className="fw-bold me-2">{t(lang, 'roaming.common.nds_code')}:</span>
                                                    <span>{item.committent_nds_registration_code}</span>
                                                </div>
                                                <div>
                                                    <span
                                                        className="fw-bold me-2">{t(lang, 'roaming.common.nds_status')}:</span>
                                                    <RoamingInvoiceVatRegStatusBadge identifier={item.committent_inn}
                                                                                     vatRegStatus={item.committent_nds_status}
                                                                                     vatRegCode={item.committent_nds_registration_code}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </td>
                                    <td>{item.catalog.code} - {item.catalog.name}</td>
                                    {hasMedical && <td>{item.serial}</td>}
                                    <td>{item.measure_id ? getMeasure(item.measure_id)?.name : item.package?.name}</td>
                                    {hasMedical && <>
                                        <td className="text-nowrap">{numeral.formats[numberFormat].format(item.base_price)}</td>
                                        <td className="text-nowrap">{numeral.formats[numberFormat].format(item.profit_rate)}</td>
                                    </>}
                                    <td>{item.quantity}</td>
                                    <td className="text-nowrap">{numeral.formats[numberFormat].format(+item.price)}</td>
                                    {hasExcise && <>
                                        <td className="text-nowrap">{item.excise_rate}</td>
                                        <td className="text-nowrap">{numeral.formats[numberFormat].format(item.excise_value)}</td>
                                    </>}
                                    <td className="text-nowrap">{numeral.formats[numberFormat].format(+item.total)}</td>
                                    <td>{item.without_vat ? 'БЕЗ НДС' : item.nds_rate}</td>
                                    <td className="text-nowrap">
                                        {item.without_vat ? 'БЕЗ НДС' : numeral.formats[numberFormat].format(+item.nds_value)}
                                    </td>
                                    <td className="text-nowrap">{numeral.formats[numberFormat].format(+item.total_with_nds)}</td>
                                    <td>{t(lang, invoiceWrapper.getItemOriginText(item.origin))}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    </Row>

                    <Row className="g-0 justify-content-end">
                        <Col xs="auto">
                            <Table borderless size="sm" className="fs--1 text-end">
                                <tbody>
                                <tr>
                                    <th className="text-900">{t(lang, 'roaming.common.subtotal')}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral.formats[numberFormat].format(invoice.total_info.total)}
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-900">{t(lang, 'roaming.common.tax')}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral.formats[numberFormat].format(invoice.total_info.nds_value)}
                                    </td>
                                </tr>
                                {hasExcise &&
                                    <tr>
                                        <th className="text-900">{t(lang, 'roaming.invoice.excise_total')}:</th>
                                        <td className="fw-semi-bold">
                                            {numeral.formats[numberFormat].format(excise)}
                                        </td>
                                    </tr>
                                }

                                {hasMedical &&
                                    <tr>
                                        <th className="text-900">{t(lang, 'roaming.invoice.medical_total')}:</th>
                                        <td className="fw-semi-bold">
                                            {numeral.formats[numberFormat].format(medical)}
                                        </td>
                                    </tr>
                                }
                                <tr className="border-top">
                                    <th className="text-900">{t(lang, 'roaming.common.total')}:</th>
                                    <td className="fw-semi-bold">
                                        {numeral.formats[numberFormat].format(invoice.total_info.total_with_nds)}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Card.Body>

                <Card.Footer>
                    <Row>
                        {zeroVatPrivilegesInfo.map(privilege =>
                            <div key={privilege.id}>
                                <i className="text-dark">
                                    <strong className="mx-1"><span
                                        className="text-danger">*</span> {t(lang, "edi.roaming.invoice.this_invoice_contains_privilege")} {t(lang, "edi.roaming.invoice.grouped_privilege_info_id")}
                                    </strong>{privilege.id}
                                    <strong
                                        className="mx-1">{t(lang, "edi.roaming.invoice.grouped_privilege_info_name")} </strong><span
                                    className="text-primary">{privilege.lgota_name}</span>
                                    <strong
                                        className="mx-1">{t(lang, "edi.roaming.invoice.grouped_privilege_info_total")} </strong>{privilege.lgota_vat_total_sum} сўм
                                    <strong
                                        className="mx-1">{t(lang, "edi.roaming.invoice.grouped_privilege_info_benefit_vat_total_sum")} </strong>{privilege.lgota_vat_sum} сўм</i>
                            </div>
                        )}
                    </Row>

                    {!isDraft &&
                        <Row className="mt-3 flex-column flex-md-row">
                            <Col xs={12} lg={6} md={6}
                                 className="d-flex justify-content-center flex-column align-items-start">
                                {!!(Array.isArray(invoice.histories) || invoice.histories?.length > 0) && invoice.histories.map((history) => {
                                    if (invoiceWrapper.isExecutorSent(history.action))
                                        return <SentHistoryActionSeal key={history.action}
                                                                      serialNumber={history.certificate.serial_number}
                                                                      signingTime={history.certificate.signing_time}
                                                                      fullName={history.certificate.full_name}
                                        />;
                                    else if (invoiceWrapper.isExecutorCanceled(history.action))
                                        return <CanceledHistoryActionSeal key={history.action}
                                                                          serialNumber={history.certificate.serial_number}
                                                                          signingTime={history.certificate.signing_time}
                                                                          fullName={history.certificate.full_name}
                                        />;
                                })}
                            </Col>
                            <Col xs={12} lg={6} md={6}
                                 className="d-flex justify-content-center flex-column align-items-end">
                                {invoice.histories.map((history) => {
                                    if (invoiceWrapper.isCustomerOrPersonDeclined(history.action))
                                        return <DeclinedHistoryActionSeal key={history.action}
                                                                          serialNumber={history.certificate.serial_number}
                                                                          signingTime={history.certificate.signing_time}
                                                                          fullName={history.certificate.full_name}
                                        />
                                    else if (invoiceWrapper.isCustomerOrPersonAccepted(history.action))
                                        return <AcceptedHistoryActionSeal key={history.action}
                                                                          serialNumber={history.certificate.serial_number}
                                                                          signingTime={history.certificate.signing_time}
                                                                          fullName={history.certificate.full_name}
                                        />
                                })}
                            </Col>
                        </Row>
                    }
                </Card.Footer>
            </Card>
        </>
    );
};

export default RoamingInvoice
