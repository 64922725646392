import React, {Fragment, useEffect, useRef, useState} from 'react';
import {FormProvider, useForm} from "react-hook-form";
import {Form} from "react-bootstrap";
import WaybillFormV2 from "./WaybillFormV2";
import axios from "axios";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import {
    RoamingWaybillV2DeliveryTypeFromExecutorToCustomer,
    RoamingWaybillV2TransportTypeRailway,
    RoamingWaybillV2TransportTypeRoadway,
    saveDraftWaybillV2Async,
    sendWaybillV2Async,
    setShowModal
} from "../../../../../app/store/reducers/roaming/roamingWaybillV2Reducer";
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../../../app/store/reducers/main/mainReducer";
import ErrorMessageAlert from "../../../../common/ErrorMessageAlert";
import {
    getOrganizationInfoAsync,
    loadCompanyProductCatalogsAsync,
    loadMeasurementsAsync
} from "../../../../../app/store/reducers/roaming/roamingReducer";
import {selectActiveOrganization} from "../../../../../app/store/reducers/auth/authReducer";
import {selectCurrentCertificate} from "../../../../../app/store/reducers/eimzo/eimzoReducer";
import {
    getDate,
    getFirstValidValue, mapItems,
    RoamingWaybillV2SingleSidedTypeToPhysicalPerson,
    TypeAdditional,
    TypeCorrective,
    TypeDefault
} from "../../../../../enum/roaming/WaybillV2Wrapper";
import useQuery from "../../invoice/form/useQuery";


export const Roadway = 1
export const Railway = 3

const SendWaybillFormV2 = ({draftWaybill, waybill, invoice, organizationInfo}) => {
    const {shipment_id, type} = useQuery();
    const dispatch = useDispatch();
    const lang = useSelector(selectLang);
    const t = Translate;
    const activeOrganization = useSelector(selectActiveOrganization);
    const currentCertificate = useSelector(selectCurrentCertificate);
    const [isSaving, setIsSaving] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const alertRef = useRef(null);

    const content = draftWaybill && JSON.parse(draftWaybill?.content) || null
    const transportType = content?.Roadway ? content.Roadway : content?.Railway ? content.Railway : null
    const waybillTransportType = waybill?.roadway ? waybill?.roadway : waybill?.railway ? waybill?.railway : null

    const methods = useForm({
        defaultValues: {
            executor: {
                inn_or_pinfl: getFirstValidValue(
                    content?.Consignor?.TinOrPinfl,
                    waybill?.executor_info?.inn,
                    waybill?.executor_info?.pinfl,
                    waybill?.executor?.inn,
                    waybill?.executor?.inn_or_pinfl,
                    invoice?.executor?.inn,
                    invoice?.executor?.person_num,
                ),
                name: getFirstValidValue(
                    content?.Consignor?.Name,
                    waybill?.executor_info?.name,
                    waybill?.executor?.name,
                    invoice?.executor?.name
                )
            },
            customer: {
                inn_or_pinfl: getFirstValidValue(
                    content?.Consignee?.TinOrPinfl,
                    waybill?.customer_info?.inn,
                    waybill?.customer_info?.pinfl,
                    waybill?.customer?.inn,
                    waybill?.customer?.inn_or_pinfl,
                    invoice?.customer?.inn,
                    invoice?.customer?.person_num,
                ),
                name: getFirstValidValue(
                    content?.Consignee?.Name,
                    waybill?.customer_info?.name,
                    waybill?.customer?.name,
                    invoice?.customer?.name
                ),
                branch: getFirstValidValue(
                    content?.Consignee?.BranchCode,
                    waybill?.customer?.branch_code,
                    waybill?.customer_info?.branch_code,
                    invoice?.customer?.branch_code
                )
            },
            freight_forwarder: {
                inn_or_pinfl: getFirstValidValue(
                    content?.FreightForwarder?.TinOrPinfl,
                    waybill?.executor_info?.inn,
                    waybill?.executor_info?.pinfl,
                    waybill?.freight_forwarder?.inn,
                    waybill?.freight_forwarder?.inn_or_pinfl,
                    organizationInfo?.person_num,
                    organizationInfo?.inn,
                ),
                name: getFirstValidValue(
                    content?.FreightForwarder?.Name,
                    waybill?.executor_info?.name,
                    waybill?.freight_forwarder?.name,
                    organizationInfo?.name
                ),
            },
            carrier: {
                inn_or_pinfl: getFirstValidValue(
                    content?.Carrier?.TinOrPinfl,
                    waybill?.carrier?.inn,
                    waybill?.carrier?.inn_or_pinfl,
                    organizationInfo?.person_num,
                    organizationInfo?.inn
                ),
                name: getFirstValidValue(
                    content?.Carrier?.Name,
                    waybill?.carrier?.name,
                    organizationInfo?.name
                ),
            },
            responsible_person: {
                inn_or_pinfl: getFirstValidValue(
                        content?.ResponsiblePerson?.Pinfl,
                        waybill?.responsible_person?.inn,
                        waybill?.responsible_person?.inn_or_pinfl,
                        organizationInfo?.person_num,
                        organizationInfo?.director_inn
                ),
                name: getFirstValidValue(
                        content?.ResponsiblePerson?.FullName,
                        waybill?.responsible_person?.name,
                        organizationInfo?.person_num ? organizationInfo?.name : organizationInfo?.director
                ),
            },
            driver: {
                inn_or_pinfl: getFirstValidValue(
                        content?.Roadway?.Driver?.Pinfl,
                        waybill?.roadway?.driver?.inn,
                        waybill?.roadway?.driver?.inn_or_pinfl,
                        organizationInfo?.person_num,
                        organizationInfo?.director_inn
                ),
                name: getFirstValidValue(
                        content?.Roadway?.Driver?.FullName,
                        waybill?.roadway?.driver?.name,
                        organizationInfo?.person_num ? organizationInfo?.name : organizationInfo?.director
                ),
            },
            loading_trustee: {
                pinfl: null,
                name: null
            },
            unloading_trustee: {
                pinfl: null,
                name: null
            },
            railway_loading_point: {
                station_id: null,
                station_name: null
            },
            railway_unloading_point: {
                station_id: null,
                station_name: null
            },
            client_contract_info: {
                number: getFirstValidValue(
                    content?.Client?.ContractNo,
                    waybill?.client_contract_info?.number,
                    waybill?.client?.contract?.number
                ),
                date: getDate(
                    getFirstValidValue(
                        content?.Client?.ContractDate,
                        waybill?.client_contract_info?.date,
                        waybill?.client?.contract?.date
                    )
                ),
            },
            payer_contract_info: {
                number: getFirstValidValue(
                    content?.Payer?.ContractNo,
                    waybill?.payer_contract_info?.number
                ),
                date: getDate(
                    getFirstValidValue(
                        content?.Payer?.ContractDate,
                        waybill?.payer_contract_info?.date
                    )
                )
            },
            executor_is_freight_forwarder: !!getFirstValidValue(
                    waybill?.executor_info?.inn,
                    invoice?.executor?.inn,
                    invoice?.executor?.person_num,
            ),
            customer_is_freight_forwarder: false,
            freight_forwarder_is_carrier: !!getFirstValidValue(
                    waybill?.executor_info?.inn,
                    organizationInfo?.person_num,
                    organizationInfo?.inn
            ),
            responsible_person_is_driver: !!getFirstValidValue(
                waybill?.executor_info?.inn,
                organizationInfo?.person_num,
                organizationInfo?.inn
            ),
            executor_is_client_employer: false,
            customer_is_client_employer: false,
            branch_code: waybill?.order?.branch?.code || null,
            waybill_type: content?.WaybillLocalType || +type ||  TypeDefault,
            oldWaybillDoc: {
                oldWaybillId: getFirstValidValue(
                    content?.WaybillLocalId,
                    waybill?.id, ''
                ),
                oldWaybillNo: getFirstValidValue(
                    content?.WaybillDoc?.WaybillNo,
                    waybill?.info?.number
                ),
                oldWaybillDate: getDate(
                    getFirstValidValue(
                        content?.WaybillDoc?.WaybillDate,
                        waybill?.info?.date
                    )
                )
            },
            client_inn_or_pinfl: getFirstValidValue(
                    content?.Client?.TinOrPinfl,
                    waybill?.client_contract_info?.inn_or_pinfl,
                    waybill?.client?.inn_or_pinfl
            ),
            delivery_type: getFirstValidValue(
                content?.DeliveryType,
                waybill?.delivery_type,
                RoamingWaybillV2DeliveryTypeFromExecutorToCustomer,
            ),
            transport_type: getFirstValidValue(
                content?.TransportType,
                waybill?.transport_type,
                RoamingWaybillV2TransportTypeRoadway,
            ),
            total_distance: getFirstValidValue(
                content?.TotalDistance?.toString(),
                waybill?.total_distance?.toString(),
                1
            ),
            delivery_cost: getFirstValidValue(
                content?.DeliveryCost?.toString(),
                waybill?.delivery_type?.toString(),
                1
            ),
            total_delivery_cost: getFirstValidValue(
                content?.TotalDistance?.toString(),
                waybill?.total_delivery_cost?.toString(),
                1
            ),
            is_single_sided: getFirstValidValue(
                content?.SingleSidedType,
                waybill?.single_sided_type,
            ),
            single_sided_type: getFirstValidValue(
                content?.SingleSidedType,
                waybill?.single_sided_type,
            ),
            waybill_info: {
                number: getFirstValidValue(
                    content?.WaybillDoc?.WaybillNo,
                    waybill?.waybill_info?.number,
                    waybill?.info?.number,
                ),
                date: getDate(
                    getFirstValidValue(
                        content?.WaybillDoc?.WaybillDate,
                        waybill?.waybill_info?.date,
                        waybill?.info?.date
                    )
                ),
            },
            contract_info: {
                number: getFirstValidValue(
                    content?.ContractDoc?.ContractNo,
                    waybill?.contract_info?.number,
                    waybill?.contract?.number,
                    invoice?.contract?.number
                ),
                date: getDate(
                    getFirstValidValue(
                        content?.ContractDoc?.ContractDate,
                        waybill?.contract_info?.date,
                        waybill?.contract?.date,
                        invoice?.contract?.date
                    )
                )
            },
            transport: {
                registration_number: getFirstValidValue(
                    waybill?.car_info?.serial_number,
                    transportType?.Truck?.RegNo,
                    waybillTransportType?.truck?.registration_number
                )
            },
            railway: {
                train_carriage_number: getFirstValidValue(
                    transportType?.TrainCarriageNumber,
                    waybillTransportType?.train_carriage_number
                ),
                train_direction: getFirstValidValue(
                    transportType?.TrainDirection,
                    waybillTransportType?.train_direction
                ),
            },
            loading_point: {
                company_address_id: null,
                branch_id: null,
                branch_code: null,
                region_code: getFirstValidValue(
                    transportType?.ProductGroups[0]?.LoadingPoint?.RegionId,
                    waybillTransportType?.product_groups[0]?.loading_point?.region_code
                ),
                region_name: getFirstValidValue(
                    transportType?.ProductGroups[0]?.LoadingPoint?.RegionName,
                    waybillTransportType?.product_groups[0]?.loading_point?.region_name
                ),
                district_code: getFirstValidValue(
                    transportType?.ProductGroups[0]?.LoadingPoint?.DistrictCode,
                    waybillTransportType?.product_groups[0]?.loading_point?.district_code
                ),
                district_name: getFirstValidValue(
                    transportType?.ProductGroups[0]?.LoadingPoint?.DistrictName,
                    waybillTransportType?.product_groups[0]?.loading_point?.district_name
                ),
                village_code: getFirstValidValue(
                    transportType?.ProductGroups[0]?.LoadingPoint?.MahallaId,
                    waybillTransportType?.product_groups[0]?.loading_point?.village_code
                ),
                village_name: getFirstValidValue(
                    transportType?.ProductGroups[0]?.LoadingPoint?.MahallaName,
                    waybillTransportType?.product_groups[0]?.loading_point?.village_name
                ),
                street: getFirstValidValue(
                    transportType?.ProductGroups[0]?.LoadingPoint?.Address,
                    waybillTransportType?.product_groups[0]?.loading_point?.street
                ),
                longitude: getFirstValidValue(
                    transportType?.ProductGroups[0]?.LoadingPoint?.Longitude,
                    waybillTransportType?.product_groups[0]?.loading_point?.longitude
                ),
                latitude: getFirstValidValue(
                    transportType?.ProductGroups[0]?.LoadingPoint?.Latitude,
                    waybillTransportType?.product_groups[0]?.loading_point?.latitude
                ),
            },
            unloading_point: {
                company_address_id: null,
                branch_id: null,
                branch_code: null,
                region_code: getFirstValidValue(
                    transportType?.ProductGroups[0]?.UnloadingPoint?.RegionId,
                    waybillTransportType?.product_groups[0]?.unloading_point?.region_code
                ),
                region_name: getFirstValidValue(
                    transportType?.ProductGroups[0]?.UnloadingPoint?.RegionName,
                    waybillTransportType?.product_groups[0]?.unloading_point?.region_name
                ),
                district_code: getFirstValidValue(
                    transportType?.ProductGroups[0]?.UnloadingPoint?.DistrictCode,
                    waybillTransportType?.product_groups[0]?.unloading_point?.district_code
                ),
                district_name: getFirstValidValue(
                    transportType?.ProductGroups[0]?.UnloadingPoint?.DistrictName,
                    waybillTransportType?.product_groups[0]?.unloading_point?.district_name
                ),
                village_code: getFirstValidValue(
                    transportType?.ProductGroups[0]?.UnloadingPoint?.MahallaId,
                    waybillTransportType?.product_groups[0]?.unloading_point?.village_code
                ),
                village_name: getFirstValidValue(
                    transportType?.ProductGroups[0]?.UnloadingPoint?.MahallaName,
                    waybillTransportType?.product_groups[0]?.unloading_point?.village_name
                ),
                street: getFirstValidValue(
                    transportType?.ProductGroups[0]?.UnloadingPoint?.Address,
                    waybillTransportType?.product_groups[0]?.unloading_point?.street
                ),
                longitude: getFirstValidValue(
                    transportType?.ProductGroups[0]?.UnloadingPoint?.Longitude,
                    waybillTransportType?.product_groups[0]?.unloading_point?.longitude
                ),
                latitude: getFirstValidValue(
                    transportType?.ProductGroups[0]?.UnloadingPoint?.Latitude,
                    waybillTransportType?.product_groups[0]?.unloading_point?.latitude
                ),
            },
            items:(() => {

                const defaultItem = {
                    name: null,
                    catalog_code: null,
                    package_code: null,
                    measurement: null,
                    quantity: 0,
                    price: 0,
                    total: 0
                };

                const waybillItems = mapItems(waybill?.items || waybillTransportType?.product_groups[0].products, item => ({
                    name: item.product || item.name,
                    catalog_code: item.catalog_class_code || item.catalog?.code,
                    package_code: item.package_code || item.package?.code,
                    measurement: item.measurement,
                    quantity: item.quantity,
                    price: item.price,
                    total: item.total
                }));

                const transportItems = mapItems(transportType?.ProductGroups?.[0]?.ProductInfo?.Products, item => ({
                    name: item.ProductName,
                    catalog_code: item.CatalogCode,
                    package_code: item.PackageCode,
                    measurement: item.PackageName,
                    quantity: item.Amount,
                    price: item.Price,
                    total: item.DeliverySum
                }));

                const invoiceItems = mapItems(invoice?.items, item => ({
                    name: item.name,
                    catalog_code: item.catalog?.code,
                    package_code: item.package?.code,
                    measurement: null,
                    quantity: item.quantity || 0,
                    price: item.price || 0,
                    total: item.total || 0
                }));

                return waybillItems || transportItems || invoiceItems || [defaultItem];
            })()
        }
    })
    useEffect(() => {
        if (shipment_id) {
           getOrganizationInfoAsync(waybill?.executor_info?.inn || waybill?.executor_info?.pinfl)
                .then(res => {
                    methods.setValue('responsible_person.inn_or_pinfl', res?.director_pinfl || res?.person_num)
                    methods.setValue('driver.inn_or_pinfl', res?.director_pinfl || res?.person_num)
                })
        }
    }, []);

    useEffect(() => {
        dispatch(loadCompanyProductCatalogsAsync(activeOrganization?.inn, lang));
        dispatch(loadMeasurementsAsync(lang));

        return () => {
            setIsSaving(false);
            setIsSending(false);
        }
    }, []);

    const onSend = async (formData, event) => {

        const actionType = event.nativeEvent.submitter.name;

        if (actionType === 'save') {
            setIsSaving(true);
        } else if (actionType === 'send') {
            setIsSending(true);
        }

        const total_distance = typeof formData.total_distance === 'string' ? formData.total_distance.replace(/ /g, '') : formData.total_distance
        const delivery_cost = typeof formData.delivery_cost === 'string' ? formData.delivery_cost.replace(/ /g, '') : formData.delivery_cost
        const total_delivery_cost = typeof formData.total_delivery_cost === 'string' ? formData.total_delivery_cost.replace(/ /g, '') : formData.total_delivery_cost

        const data = {
            delivery_type: formData.delivery_type,
            transport_type: formData.transport_type,
            waybill_info: {
                number: formData.waybill_info.number,
                date: dayjs(formData.waybill_info.date).format('YYYY-MM-DD')
            },
            contract_info: formData?.contract_info?.number ? {
                number: formData.contract_info.number || null,
                date: formData.contract_info?.date ? dayjs(formData.contract_info.date).format('YYYY-MM-DD') : null
            } : null,
            customer_is_freight_forwarder: formData.customer_is_freight_forwarder,
            executor_is_freight_forwarder: formData.executor_is_freight_forwarder,
            freight_forwarder_is_carrier: formData.freight_forwarder_is_carrier,
            type: +formData.waybill_type,
            responsible_person: {
                inn_or_pinfl: formData.responsible_person.inn_or_pinfl,
                name:formData.responsible_person.name
            },
            responsible_person_is_driver: formData.responsible_person_is_driver,
            executor_is_client_employer: formData.client_inn_or_pinfl === formData.executor.inn_or_pinfl,
            customer_is_client_employer: formData.customer.inn_or_pinfl && formData.client_inn_or_pinfl === formData.customer.inn_or_pinfl,
            total_distance: +total_distance,
            delivery_cost: +delivery_cost,
            total_delivery_cost: +total_delivery_cost,
            client_contract_info: formData?.client_contract_info?.number ? {
                number: formData.client_contract_info?.number || null,
                date: formData.client_contract_info?.date ? dayjs(formData.client_contract_info.date).format('YYYY-MM-DD') : null
            } : null,
            payer_contract_info: formData?.payer_contract_info?.number ? {
                number: formData.payer_contract_info?.number || null,
                date: formData.payer_contract_info?.date ? dayjs(formData.payer_contract_info.date).format('YYYY-MM-DD') : null
            } : null,
            loading_point: {
                company_address_id: formData.loading_point.company_address_id,  // optional
                branch_id: formData.loading_point.branch_id, // optional
                branch_code: formData.loading_point.branch_code, // optional
                region_code: formData.loading_point.region_code, // required when company_address_id = null and branch_id = null
                region_name: formData.loading_point.region_name, // required when company_address_id = null and branch_id = null
                district_code: formData.loading_point.district_code, // required when company_address_id = null and branch_id = null
                district_name: formData.loading_point.district_name, // required when company_address_id = null and branch_id = null
                village_code: formData.loading_point.village_code, // required when company_address_id = null and branch_id = null
                village_name: formData.loading_point.village_name, // required when company_address_id = null and branch_id = null
                street: formData.loading_point.street, // required when company_address_id = null and branch_id = null
                longitude: +formData.loading_point.longitude, // required when company_address_id = null and branch_id = null
                latitude: +formData.loading_point.latitude,// required when company_address_id = null and branch_id = null
            },
            unloading_point: {
                company_address_id: formData.unloading_point.company_address_id,  // optional
                branch_id: formData.unloading_point.branch_id, // optional
                branch_code: formData.unloading_point.branch_code, // optional
                region_code: formData.unloading_point.region_code, // required when company_address_id = null and branch_id = null
                region_name: formData.unloading_point.region_name, // required when company_address_id = null and branch_id = null
                district_code: formData.unloading_point.district_code, // required when company_address_id = null and branch_id = null
                district_name: formData.unloading_point.district_name, // required when company_address_id = null and branch_id = null
                village_code: formData.unloading_point.village_code, // required when company_address_id = null and branch_id = null
                village_name: formData.unloading_point.village_name, // required when company_address_id = null and branch_id = null
                street: formData.unloading_point.street, // required when company_address_id = null and branch_id = null
                longitude: +formData.unloading_point.longitude, // required when company_address_id = null and branch_id = null
                latitude: +formData.unloading_point.latitude,// required when company_address_id = null and branch_id = null
            },
            items: formData.items.map(x => {
                return {
                    name: x.name,
                    catalog_code: x.catalog_code,
                    package_code: x.package_code?.code, // optional
                    measurement:  x.package_code?.code?.toString() || x.package_code?.toString() || x.measurement,  // package code/package name,
                    quantity: +x.quantity,
                    price: +x.price,
                    // total: +x.total
            }})
        }

        if (data.delivery_type === RoamingWaybillV2DeliveryTypeFromExecutorToCustomer) {
            data['contract_info'] = {
                number: formData.contract_info.number,
                date: dayjs(formData.contract_info.date).format('YYYY-MM-DD')
            }
            data['customer'] = {
                inn_or_pinfl: formData.customer.inn_or_pinfl,
                name: formData.customer.name,
                branch_code: formData.customer?.branch?.code || null,
                branch_name: formData.customer?.branch?.name || null
            }
        }

        if (!data.customer_is_freight_forwarder && !data.executor_is_freight_forwarder) {
            data['freight_forwarder'] = {
                inn_or_pinfl: formData.freight_forwarder.inn_or_pinfl,
                name:formData.freight_forwarder.name
            }
        }

        if (!data.freight_forwarder_is_carrier) {
            data['carrier'] = {
                inn_or_pinfl: formData.carrier.inn_or_pinfl,
                name:formData.carrier.name
            }
        }

        if (!data.responsible_person_is_driver) {
            data['driver'] = {
                inn_or_pinfl: formData.driver.inn_or_pinfl,
                name:formData.driver.name
            }
        }

        if (data.type === TypeAdditional || data.type === TypeCorrective) {
            data['old_waybill'] = {
                id: formData.oldWaybillDoc?.oldWaybillId,
                number: formData.oldWaybillDoc?.oldWaybillNo,
                date: dayjs(formData.oldWaybillDoc?.oldWaybillDate).format("YYYY-MM-DD")
            }
        }

        if (data.transport_type === RoamingWaybillV2TransportTypeRoadway) {
            data['transport'] = {
                registration_number: formData.transport?.registration_number?.registration_number,
                model: formData.transport?.registration_number?.model,
            }
        }

        if (formData.is_single_sided) {
            delete data['customer']
            data.single_sided_type = formData.single_sided_type
        }

        if (data.transport_type === RoamingWaybillV2TransportTypeRailway) {
            delete data['loading_point']
            delete data['unloading_point']
            data['train_carriage_number'] = formData.railway?.train_carriage_number
            data['train_direction'] = formData.railway?.train_direction
            data['railway_loading_point'] = {
                station_id: formData.railway_unloading_point?.station_id?.station_id,
                station_name: formData.railway_unloading_point?.station_id?.station_name
            }
            data['railway_unloading_point'] = {
                station_id: formData.railway_unloading_point?.station_id?.station_id,
                station_name: formData.railway_unloading_point?.station_id?.station_name
            }
            data['loading_trustee'] = {
                pinfl: formData.loading_trustee?.pinfl,
                name: formData.loading_trustee?.name
            }
            data['unloading_trustee'] = {
                pinfl: formData.unloading_trustee?.pinfl,
                name: formData.unloading_trustee?.name
            }
        }

        const response = await axios.post('/document-generator/roaming/waybill-v2/generate/send-hash-code', data)
        if (actionType === 'save') {
            await saveDraftWaybillV2({
                id: response.data?.id,
                content: response.data?.hashcode
            })
        } else if (actionType === 'send') {
            await sendWaybillV2({
                id: response.data?.id,
                hashCode: response.data?.hashcode,
            });
        }

    }

    const saveDraftWaybillV2 = (data) => {
        saveDraftWaybillV2Async(data)
            .then(() => {
                toast.success(t(lang, 'roaming.waybill.save.toast.saved_success'))
                dispatch(setShowModal(false))
            })
            .catch(error => {
                setErrorMessage(error?.message || null);
                alertRef?.current?.scrollIntoView();
            })
            .finally(() => setIsSaving(false))
    }

    const sendWaybillV2 = ({id, hashCode}) => {
        sendWaybillV2Async(currentCertificate, lang, {id, hashCode})
            .then(() => {
                toast.success(t(lang, 'roaming.waybill.send.toast.register_success'))
                dispatch(setShowModal(false))
            })
            .catch(error => {
                setErrorMessage(error.message || null);
                alertRef.current.scrollIntoView();
            })
            .finally(() => setIsSending(false))
    }

    return (
        <Fragment>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSend)}>
                    {errorMessage && <ErrorMessageAlert ref={alertRef} errorMessage={errorMessage}/>}
                    <WaybillFormV2 shipmentId={shipment_id} isSending={isSending} isSaving={isSaving} content={content || waybill} />
                </Form>
            </FormProvider>
        </Fragment>
    );
};

export default SendWaybillFormV2;
