import {useSelector} from "react-redux";
import {
    selectDateFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../../app/store/reducers/main/mainReducer";
import {selectAllContractor} from "../../../../../app/store/reducers/contractor/contractorReducer";
import {selectCurrency} from "../../../../../app/store/reducers/currency/currencyReducer";
import {selectWarehouses} from "../../../../../app/store/reducers/warehouse/warehouseReducer";
import {useHistory} from "react-router";
import {selectItems} from "../../../../../app/store/reducers/item/itemReducer";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {toast} from "react-toastify";
import dayjs from "dayjs";
import {Button, Card, Col, Form, InputGroup, Row, Spinner, Table} from "react-bootstrap";
import SoftBadge from "../../../../common/SoftBadge";
import {isApprovedCheckedWarehouseOperation} from "../../enum/warehouseOperationWrapper";
import numeral from "numeral";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import IconButton from "../../../../common/IconButton";
import ContractorSelect from "../../../common/ContractorSelect";
import WarehouseOperationCategorySelect from "../../components/WarehouseOperationCategorySelect";
import WarehouseOperationItemSelect from "../../components/WarehouseOperationItemSelect";
import WarehouseOperationWarehouseSelect from "../../components/WarehouseOperationWarehouseSelect";
import InputGroupWithCurrency from "../../../price/components/InputGroupWithCurrency";
import {ErrorMessage} from "@hookform/error-message";
import PackageTypeItem from "../../components/PackageTypeItem";
import {faArrowLeftLong} from "@fortawesome/free-solid-svg-icons/faArrowLeftLong";
import WarehouseOperationWithoutPayment from "../../components/WarehouseOperationWithoutPayment";
import WarehouseOperationImageModal from "../../components/WarehouseOperationImageModal";
import WarehouseOperationDeleteItem from "../../components/WarehouseOperationDeleteItem";
import WarehouseOperationFooterCurrencyTotalSum from "../../components/WarehouseOperationFooterCurrencyTotalSum";
import {
    deleteReturnPurchasePaymentAsync,
    returnPurchaseApproveAsync, returnPurchaseChangeContractorAsync,
    returnPurchaseDeleteItemAsync,
    returnPurchasePaymentRegisterAsync,
    returnPurchasePaymentUpdateAsync,
    returnPurchaseUpdateDateAsync,
    returnPurchaseUpdateItemAsync, returnPurchaseUpdateNumberAsync,
    selectReturnPurchaseDefaultsSettings
} from "../../../../../app/store/reducers/warehouse-operation/returnPurchaseReducer";
import ReturnPurchaseUpdateItemModal from "../components/ReturnPurchaseUpdateItemModal";
import ReturnPurchaseSettings from "../components/ReturnPurchaseSettings";
import EventBus from "../../../../../app/eventbus/EventBus";
import {ADD_CONTRACTOR_SUCCESS} from "../../../../../app/eventbus/contractor/contractorEvents";
import {SCAN_SUCCESS} from "../../../../../app/eventbus/itemEvents";
import WOWithoutDiscountPayment from "../../components/WOWithoutDiscountPayment";
import {ADD_EMPLOYEE_SUCCEED} from "../../../../../app/eventbus/employeeEvents";
import EmployeeSelect from "../../../common/EmployeeSelect";
import CommonDeleteModal from "../../../../common/CommonDeleteModal";
import CashBoxSelect from "../../../cashbox/common/CashBoxSelect";
import {refundChangeContractorAsync} from "../../../../../app/store/reducers/warehouse-operation/refundReducer";
import {UZS} from "../../../../../enum/CurrencyCode";


const colIndexItem = 0
const colIndexQuantity = 2
const colIndexPrice = 3
const colIndexTotal = 4
const colIndexWarehouse = 6


const colCoordinateZero = 0
const rowCoordinateOne = 1
const subRowoordinateTwo = 2


const ReturnPurchaseForm = ({returnPurchase, loading, addItem, isEditing}) => {
    const returnPurchaseDefaultsSettings = useSelector(selectReturnPurchaseDefaultsSettings)
    const numberFormat = useSelector(selectNumberFormat)
    const contractors = useSelector(selectAllContractor)
    const currencies = useSelector(selectCurrency)
    const dateFormat = useSelector(selectDateFormat)
    const warehouses = useSelector(selectWarehouses)
    const history = useHistory()
    const items = useSelector(selectItems)
    const form = useFormContext()
    const lang = useSelector(selectLang)
    const t = Translate


    // state
    const [categoryItems, setCategoryItems] = useState(null)
    const [priceType, setPriceType] = useState(true)
    const [isHover, setIsHover] = useState([])
    const [filter, setFilter] = useState('')
    const [loadingContractor, setLoadingContractor] = useState(false)
    const {errors} = form?.formState


    // watch
    const packageMeasurements = useWatch({name: 'package_measurements', exact: true})
    const contractorId = useWatch({name: 'contractor_id', exact: true})
    const warehouseId = useWatch({name: 'warehouse_id', exact: true})
    const numberWatch = useWatch({name: 'number', exact: true})
    const commonPrice = form.watch('commonPrice')
    const warehouse = form.watch('warehouse')
    const quantity = form.watch('quantity')
    const itemId = useWatch({name: 'item_id', exact: true})

    const totalSum = +quantity * (+packageMeasurements?.quantity || 0) * +commonPrice?.amount
    const totalSumCurrencyName = currencies?.find(x => x.id === commonPrice?.currency_id)?.name
    const contractor = contractors.find(f => f?.id === contractorId)


    useEffect(() => {
        if(!contractor) {
            form?.setValue("contractor_id", returnPurchaseDefaultsSettings?.defaultClientId)
        }

        if (!warehouseId) {
            form?.setValue("warehouse_id", returnPurchaseDefaultsSettings?.defaultWarehouseId)
        }
        const onContractorAddedHandler = EventBus.on(ADD_CONTRACTOR_SUCCESS, (res) => {
            form?.setValue("contractor_id", res?.id)
        })
        const onEmployeeAddedHandler = EventBus.on(ADD_EMPLOYEE_SUCCEED, (res) => {
            form?.setValue("employee_id", res?.data?.id)
        })
        const onScanSuccessHandler = EventBus.on(SCAN_SUCCESS, onScan);

        return () => {
            EventBus.remove(SCAN_SUCCESS, onScanSuccessHandler);
            EventBus.remove(ADD_CONTRACTOR_SUCCESS, onContractorAddedHandler)
            EventBus.remove(ADD_EMPLOYEE_SUCCEED, onEmployeeAddedHandler)
        }
    },[returnPurchaseDefaultsSettings])

    useEffect(() => {
        if (warehouses?.length === 1 && warehouse) {
            form?.setValue("warehouse_id", warehouse[0]?.id)
        }
    },[warehouse])

    useEffect(() => {
        form?.setValue("employee_id", returnPurchaseDefaultsSettings?.defaultEmployeeId)
    }, [returnPurchaseDefaultsSettings?.defaultEmployeeId]);

    const onScan = (value) => {
        if (value.length > 14){
            const quantityGet = form?.getValues('quantity')

            form?.setValue('marks', [value])
            form?.setValue('quantity', +quantityGet + 1)

            addItem("addOrEditReturnPurchase")
        }
    }

    const onChangeCategory = (option) => {
        setCategoryItems(items.filter(i => i?.item?.category?.id === option?.id))
    }

    const onChangeItem = (option) => {
        form.setValue('warehouse', option ? option?.warehouse_states?.warehouse_items : null)
        setPrice({priceType: priceType, item: option})
    }

    const onChangePriceType = (option) => {
        const product = items?.find(item => item?.item?.id === itemId)
        setPriceType(option)
        setPrice({priceType: option, item: product})
    }

    const setPrice = ({priceType, item}) => {
        if (priceType) {
            form.setValue('commonPrice.amount', item ? item?.price?.common_price?.amount : 0)
            form.setValue('commonPrice.currency_id', item ? item?.price?.common_price?.currency?.global_currency_id : currencies?.find(c => c?.code === UZS)?.id)
            form.setValue('constantPrice', item ? item?.price?.common_price?.amount : 0)
        } else {
            form.setValue('commonPrice.amount', item ? item?.price?.bulk_price?.amount : 0)
            form.setValue('commonPrice.currency_id', item ? item?.price?.bulk_price?.currency?.global_currency_id : currencies?.find(c => c?.code === UZS)?.id)
            form.setValue('constantPrice', item ? item?.price?.bulk_price?.amount : 0)
        }
    }


    // focus start
    const onFocusContractor = () => {
        if (!contractorId && !quantity && !itemId) {
            return true
        }
    }

    const onFocusItem = () => {
        if (contractorId && !itemId) {
            return true
        }
    }

    const onFocusWarehouse = () => {
        if (itemId && !warehouseId) {
            return true
        }
    }

    const onFocusQuantity = () => {
        if (warehouseId && itemId && contractorId && !quantity) {
            document.getElementById('quantity')?.focus()
            return true
        }
    }

    useEffect(() => {
        onFocusQuantity()
    }, [warehouseId, itemId])
    // focus end


    // payment start
    const onSubmitPay = async (pay) => {
        if (returnPurchase?.payment) {
            await returnPurchasePaymentUpdateAsync({returnPurchaseId: pay?.id, payment: pay?.payment})
                .then(() => {
                })
                .catch(() => {

                })
        }

        if (!returnPurchase?.payment && returnPurchase?.is_approved) {
            await returnPurchasePaymentRegisterAsync({returnPurchaseId: pay?.id, payment: pay?.payment})
                .then(() => {
                })
                .catch(() => {

                })

        }

        if (!returnPurchase?.is_approved) {
            await returnPurchaseApproveAsync({returnPurchaseId: pay?.id, payment: pay?.payment || null})
                .then(() => {
                })
                .catch(() => {

                })
        }
    }
    // payment end


    const data = useMemo(() => {
        return returnPurchase?.items?.filter(i => {
            const searchLower = filter.trim().toLowerCase();

            // search by barcode
            {
                if (searchLower.length >= 8 && searchLower.length <= 13 && !isNaN(searchLower)) {
                    const searchBarcode = items.filter(it => it.item?.barcodes?.some((b) => b?.includes(searchLower)))

                    if (searchBarcode.some(bar => bar?.item?.name.trim().toLowerCase() === i?.warehouse_item?.name.trim().toLowerCase()))
                        return true;
                }
            }

            // search by name
            {
                const filterNameParts = searchLower?.replaceAll('  ', ' ')?.split(' ');
                const itemLowerName = i.warehouse_item?.name.toLowerCase();
                if (filterNameParts?.length === filterNameParts?.filter(fnp => itemLowerName?.indexOf(fnp) > -1)?.length)
                    return true;
            }
        })
    }, [returnPurchase, filter])

    const groupByItemIdAndPrice = (filterItems) => {
        var groupByItemIdPriceIdCurrencyId = {}

        filterItems?.forEach((item) => {
            const itemID = item?.warehouse_item?.id;
            const currencyID = item?.price?.currency?.id;
            const amount = item?.price?.amount;

            const key = `${itemID}_${currencyID}_${amount}`

            if (key in groupByItemIdPriceIdCurrencyId) {
                groupByItemIdPriceIdCurrencyId[key].sub.push(item)
            } else {
                if (key) {
                    groupByItemIdPriceIdCurrencyId[key] = {
                        item: {
                            id: item?.warehouse_item?.id,
                            name: item?.warehouse_item?.name
                        },
                        sub: [item]
                    }
                }
            }

        })

        return Object.values(groupByItemIdPriceIdCurrencyId)
    }
    const groupByItemPriceCurrencyId = groupByItemIdAndPrice(data?.filter(f => !f?.is_deleted))



    // hover mouser over
    const handleMouseOver = ({col, row, subRow}) => {
        setIsHover([col, row, subRow])
    }


    const updateItem = async ({itemOne, quantity, price, totalSum, warehouseId, item}) => {
        const operationItem = {
            operation_item_id: item?.id,
            item_id: item?.warehouse_item?.id,
            warehouse_id: item?.warehouse_item?.warehouse?.id,
            quantity: item?.quantity,
            price: {
                amount: item?.price?.amount,
                currency_id: item?.price?.currency?.id,
            },
        }


        if (itemOne) {
            operationItem.item_id = itemOne?.item?.id
            operationItem.price.amount = itemOne?.price?.common_price.amount
            operationItem.price.currency_id = itemOne?.price?.common_price?.currency?.global_currency_id
        }
        if(quantity) {
            operationItem.quantity = +quantity
        }
        if(price) {
            operationItem.price.amount = +price
        }
        if(totalSum) {
            operationItem.quantity = +(+totalSum / item?.price?.amount).toFixed(2)
        }
        if(warehouseId) {
            operationItem.warehouse_id = warehouseId
        }

        await returnPurchaseUpdateItemAsync({
            returnPurchaseId: returnPurchase?.id,
            operation: operationItem
        })
            .then(() => {
                toast.success(t(lang, "warehouse.operation.item.common.update.toast.success"))
            })
            .catch(() => {
                toast.error(t(lang, "warehouse.operation.item.common.update.toast.error"))
            })
            .finally(() => {
                setIsHover([])

            })
    }

    // delete item
    const onDeleteItem = async ({id, itemId}) => {
        await returnPurchaseDeleteItemAsync({returnPurchaseId: id, operationId: itemId})
            .then((response) => {
                toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
            })
            .catch((error) => {
                toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
            })
    }
    const onDateChanged = async (e, date) => {
        if (e === "Save") {
            await returnPurchaseUpdateDateAsync({
                returnPurchaseId: returnPurchase?.id,
                date: date ? dayjs(date).format("YYYY-MM-DD HH:mm:ss") : dayjs(returnPurchase?.date).format("YYYY-MM-DD HH:mm:ss")
            })
                .then(() => {
                    form.setValue('date', date ? dayjs(date, "YYYY-MM-DD HH:mm").toDate() : dayjs(returnPurchase?.date, "YYYY-MM-DD HH:mm").toDate())
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
        }
        if (e === "Cancel") {
            form.setValue('date', dayjs(returnPurchase?.date, "YYYY-MM-DD HH:mm").toDate())
        }
    }


    const onNumberChanged = (e, number) => {
        if (e === "Save") {
            if (number?.trim() && number !== returnPurchase?.number) {
                returnPurchaseUpdateNumberAsync({returnPurchaseId: returnPurchase?.id, number: number})
                    .then(() => {
                        form.setValue('number', number ? number : returnPurchase?.number)
                        toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    })
                    .catch(() => {
                        toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                    })
            }
        }
        if (e === "Cancel") {
            form.setValue('number', returnPurchase?.number)
        }
    }

    const deleteReturnPurchasePayment = (returnPurchaseId) => {
        deleteReturnPurchasePaymentAsync(returnPurchaseId)
            .then(() => toast.success(t(lang, 'common.toast.success')))
            .catch(() => toast.error(t(lang, 'common.toast.error')))
    }

    const onContractorChanged = async (e, value, setValue, defaultValue) => {
        if (e === 'Save') {
            setLoadingContractor(true)
            await returnPurchaseChangeContractorAsync({returnPurchaseId: returnPurchase.id, contractorId: value?.id})
                .then(() => {
                    toast.success(t(lang, "warehouse.operation.item.common.toast.success"))
                    form.setValue('contractor_id', value?.id)
                })
                .catch(() => {
                    toast.error(t(lang, "warehouse.operation.item.common.toast.error"))
                })
                .finally(() => {
                    setLoadingContractor(false)
                })
        } else {
            setValue(contractors.find(i => i.id === defaultValue))
        }
    }



    return(
        <>
            <Card className={'mb-2'}>
                <Card.Body>
                    <IconButton
                        size={'lg'}
                        variant="falcon-warning"
                        icon={faArrowLeftLong}
                        className="me-2"
                        onClick={() => history.goBack()}
                    >
                        <span className="d-none d-sm-inline-block ms-1">
                            {t(lang, "warehouse.operation.item.common.button.back")}
                        </span>
                    </IconButton>
                </Card.Body>
            </Card>

            <Card className="bg-light">
                <Card.Header>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-between">
                            <h4 className="text-dark">
                                {t(lang, "warehouse.operation.item.common.new_return.purchase")}
                            </h4>
                            <div>
                                {returnPurchase &&
                                    <>
                                        <span className="me-2">{t(lang, "warehouse.operation.item.common.sale_status")}: </span>
                                        <SoftBadge bg={returnPurchase?.is_approved ? 'success' : 'warning'}>
                                            {t(lang, isApprovedCheckedWarehouseOperation(!!returnPurchase?.is_approved))}
                                        </SoftBadge>
                                    </>
                                }
                                <ReturnPurchaseSettings/>
                            </div>
                        </Col>
                        {contractor &&
                            <Col xs={12} className="d-flex flex-column">
                                <div className={"d-flex me-3 fw-bold"}>
                                    <h5 className="d-flex justify-content-end fw-bold">{t(lang, "warehouse.operation.item.common.real_credit")}:</h5>
                                    <div className="d-flex fs-1">
                                        {contractor?.debts?.length > 0 ? contractor?.debts?.map((item, index) => {
                                                if (item?.amount) {
                                                    return (
                                                        <span key={index}
                                                              className="d-flex text-nowrap justify-content-end mx-2">
                                                        {numeral.formats[numberFormat].format(item?.amount)} <span
                                                            className="text-info ms-1">{item?.currency?.name}</span>
                                                    </span>
                                                    )
                                                }
                                            })
                                            :
                                            <span className="d-flex justify-content-end mx-2">0 сум</span>
                                        }
                                    </div>
                                </div>
                                {contractor?.labels?.length > 0 &&
                                    <div className="d-flex mt-1">
                                        <h5 className="d-flex justify-content-end fw-bold">{t(lang, "crm.contractor.datatable.label")}:</h5>
                                        <div className="d-flex fs-1">
                                            {contractor?.labels?.map((label, index) => {
                                                return (
                                                    <SoftBadge className={'mx-1'} key={index}>{label?.name}</SoftBadge>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                            </Col>
                        }
                        {returnPurchaseDefaultsSettings?.isCheckedViewDateTime &&
                            <Col xs={12} lg={6}>
                                <Form.Group>
                                    <Form.Label>{t(lang, 'Дата')}</Form.Label>
                                    <Controller
                                        name={`date`}
                                        rules={{
                                            required: t(lang, 'warehouse.operation.item.common.validation.is_required'),
                                        }}
                                        render={({field}) => {
                                            return (
                                                <InputGroup>
                                                    <div className="form-control p-0">
                                                        <DatePicker
                                                            selected={field.value}
                                                            onChange={field.onChange}
                                                            placeholderText={t(lang, "date")}
                                                            dateFormat={`${dateFormat} HH:mm`}
                                                            showTimeSelect
                                                            timeFormat="p"
                                                            timeIntervals={60}
                                                            wrapperClassName={classNames({
                                                                'is-invalid': errors?.date
                                                            })}
                                                            className={classNames('form-control', {
                                                                'is-invalid': errors?.date,
                                                            })}
                                                        />
                                                    </div>
                                                    {dayjs(field?.value).format("YYYY-MM-DD HH:mm:ss") !== returnPurchase?.date && returnPurchase &&
                                                        <>
                                                            <IconButton
                                                                variant={"falcon-info"}
                                                                title={"Save"}
                                                                size="md"
                                                                onClick={(e) => onDateChanged("Save", field?.value)}
                                                                icon={"check"}
                                                            />
                                                            <IconButton
                                                                variant="danger"
                                                                title={"Cancel"}
                                                                size="md"
                                                                onClick={(e) => onDateChanged("Cancel")}
                                                                icon="cancel"
                                                            />
                                                        </>
                                                    }
                                                </InputGroup>
                                            )
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.date?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        }
                        <Col xs={12} lg={6}>
                            <Form.Group>
                                <Form.Label>{t(lang, "Сотрудника")}</Form.Label>
                                <Controller
                                    name="employee_id"
                                    render={({field}) => {
                                        return (
                                            <EmployeeSelect
                                                classNamePrefix="react-select"
                                                defaultValue={field?.value}
                                                onChange={(option) => {
                                                    field.onChange(option ? option?.id : null)
                                                }}
                                                isClearable={true}
                                                allowAdd={true}
                                                allowEdit={true}
                                            />
                                        )
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} xl={6}>
                            <Form.Group>
                                <Form.Label>{t(lang, "warehouse.navbar.routes.warehouse")}</Form.Label>
                                <Controller
                                    name="warehouse_id"
                                    rules={{
                                        required: t(lang, "warehouse.operation.item.common.validation.is_required")
                                    }}
                                    render={({field}) => {
                                        return (
                                            <WarehouseOperationWarehouseSelect
                                                options={warehouse}
                                                classNamePrefix="react-select"
                                                onFocus={onFocusWarehouse()}
                                                defaultValue={field?.value}
                                                onChange={(option) => {
                                                    field.onChange(option ? option?.id : null)
                                                }}
                                                className={classNames({
                                                    'is-invalid': errors?.warehouse_id
                                                })}
                                            />
                                        )
                                    }}
                                />
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {errors?.warehouse_id?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col  xs={12} lg={6}>
                            <Form.Group>
                                <Form.Label>{t(lang, "cashbox.bind.account.title")}</Form.Label>
                                <Controller
                                    name={'cashbox_id'}
                                    render={({field}) => (
                                        <CashBoxSelect
                                            classNamePrefix="react-select"
                                            defaultValue={field.value}
                                            onChange={option => field.onChange(option ? option.id : null)}
                                        />
                                    )} />
                            </Form.Group>
                        </Col>
                        {returnPurchaseDefaultsSettings?.isCheckedViewNumber &&
                            <Col xs={12} lg={6}>
                                <Form.Group className="d-flex flex-column">
                                    <Form.Label>{t(lang, "№")}</Form.Label>
                                    <InputGroup>
                                        <div className="form-control p-0">
                                            <Form.Control
                                                type="text"
                                                {...form.register("number")}
                                                placeholder={t(lang, "Номер")}
                                                className={classNames("form-control w-100")}
                                            />
                                        </div>
                                        {numberWatch !== returnPurchase?.number && returnPurchase &&
                                            <>
                                                <IconButton
                                                    variant={"falcon-info"}
                                                    title={"Save"}
                                                    size="md"
                                                    onClick={(e) => onNumberChanged("Save", numberWatch)}
                                                    icon={"check"}
                                                />
                                                <IconButton
                                                    variant="danger"
                                                    title={"Cancel"}
                                                    size="md"
                                                    onClick={(e) => onNumberChanged("Cancel")}
                                                    icon="cancel"
                                                />
                                            </>
                                        }
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        }
                        <Col xs={12} lg={6}>
                            <Form.Group>
                                <Form.Label>{t(lang, "warehouse.operation.item.common.executor")}</Form.Label>
                                <Controller
                                    name="contractor_id"
                                    rules={{
                                        required: t(lang, "warehouse.operation.item.common.validation.is_required")
                                    }}
                                    render={({field}) => {
                                        return (
                                            <ContractorSelect
                                                classNamePrefix="react-select"
                                                onFocus={onFocusContractor()}
                                                defaultValue={field?.value}
                                                onChange={(option) => {
                                                    field.onChange(option ? option?.id : null)
                                                }}
                                                isClearable={true}
                                                allowAdd={true}
                                                allowEdit={true}
                                                className={classNames({
                                                    'is-invalid': errors?.contractor_id,
                                                })}
                                                isEditing={groupByItemPriceCurrencyId.length > 0 && true || isEditing}
                                                loading={loadingContractor}
                                                changeContractor={onContractorChanged}
                                            />
                                        )
                                    }}
                                />
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {errors?.contractor_id?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        {returnPurchaseDefaultsSettings?.isCheckedViewCategories &&
                            <Col xs={12} lg={6}>
                                <Form.Label>{t(lang, "Категории")}</Form.Label>
                                <Controller
                                    name="category_id"
                                    render={({field}) => {
                                        return (
                                            <WarehouseOperationCategorySelect
                                                classNamePrefix="react-select"
                                                defaultValue={field?.value}
                                                onChange={option => {
                                                    field?.onChange(option ? option?.id : null)
                                                    onChangeCategory(option)
                                                }}
                                            />
                                        )
                                    }}
                                />
                            </Col>
                        }
                        <Col xs={12} lg={6}>
                            <Form.Group>
                                <Form.Label>{t(lang, "warehouse.operation.item.common.product_name")}</Form.Label>
                                <Controller
                                    name="item_id"
                                    rules={{
                                        required: t(lang, "warehouse.operation.item.common.validation.is_required")
                                    }}
                                    render={({field}) => {
                                        return (
                                            <WarehouseOperationItemSelect
                                                classNamePrefix="react-select"
                                                onFocus={onFocusItem()}
                                                warehouseId={warehouseId}
                                                options={categoryItems || items}
                                                allowEdit={true}
                                                allowAdd={true}
                                                allowImage={true}
                                                defaultValue={field?.value}
                                                defaultsSettings={returnPurchaseDefaultsSettings}
                                                isClearable
                                                onChange={option => {
                                                    field?.onChange(option ? option?.item?.id : null)
                                                    onChangeItem(option)
                                                }}
                                                className={classNames({
                                                    'is-invalid': errors?.item_id
                                                })}
                                            />
                                        )
                                    }}
                                />
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {errors?.item_id?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group>
                                <Form.Label>{t(lang, "warehouse.operation.item.common.sum")}</Form.Label>
                                <Controller
                                    name='commonPrice'
                                    render={({field}) => (
                                        <InputGroupWithCurrency
                                            defaultCurrencyId={commonPrice?.currency_id}
                                            defaultPrice={commonPrice?.amount}
                                            defaultBulkPrice={priceType}
                                            onChangeBulkPrice={(priceTypeOne) => {
                                                onChangePriceType(priceTypeOne)
                                            }}
                                            onChangePrice={(price) => {
                                                form?.setValue('commonPrice.amount', price);
                                                form?.trigger('commonPrice');
                                            }}
                                            onChangeCurrency={(currency) => {
                                                form?.setValue('commonPrice.currency_id', currency ? currency?.id : null);
                                                form?.trigger('commonPrice');
                                            }}
                                            inputProps={{
                                                className: classNames({
                                                    'is-invalid': errors?.commonPrice
                                                }, 'form-control'),
                                                placeholder: t(lang, "warehouse.operation.item.common.sum:placeholder"),
                                                options: {
                                                    numeral: true,
                                                    delimiter: ' ',
                                                    numeralDecimalScale: 4
                                                }
                                            }}
                                            className={classNames({
                                                'is-invalid': errors?.commonPrice
                                            })}
                                        />
                                    )}
                                />
                                {/*<span className="text-info fs--1">*/}
                                {/*    {t(lang, "warehouse.operation.item.common.costPrice")}*/}
                                {/*    {numeral.formats[numberFormat].format(13000)}*/}
                                {/*</span>*/}
                                <ErrorMessage name='commonPrice'
                                              errors={errors}
                                              as={<Form.Control.Feedback type="invalid"/>}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group className="d-flex flex-column">
                                <Form.Label>{t(lang, "warehouse.operation.item.common.quantity")}</Form.Label>
                                <InputGroup>
                                    <div className="d-flex w-100">
                                        <Form.Control
                                            type="text"
                                            id="quantity"
                                            {...form.register("quantity", {
                                                required: t(lang, "warehouse.operation.item.common.validation.is_required"),
                                                pattern: {
                                                    value: /^[0-9]*[\.,]?[0-9]*$/,
                                                    message: "100 | 1.2 | 1,5"
                                                }
                                            })}
                                            placeholder={t(lang, "warehouse.operation.item.common.quantity:placeholder")}
                                            // isInvalid={errors?.quantity}
                                            className={classNames("form-control w-100")}
                                        />
                                        <Controller
                                            name="package_measurements"
                                            render={({field}) => {
                                                return (
                                                    <PackageTypeItem
                                                        itemId={itemId}
                                                        defaultValue={field?.value}
                                                        onChange={option => {
                                                            field?.onChange(option)
                                                        }}
                                                    />
                                                )
                                            }}
                                        />
                                    </div>
                                </InputGroup>
                                <Form.Control.Feedback type="invalid" className="d-block">
                                    {errors?.quantity?.message}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>{t(lang, "Описание")}</Form.Label>
                                <Form.Control
                                    placeholder="Описание"
                                    as="textarea"
                                    rows={4}
                                    name="note"
                                    {...form?.register("note")}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Body>
                    <Row>
                        <Col xs={12} className="d-flex flex-wrap justify-content-between">
                            <div>
                                <Button type="submit" disabled={loading} variant="falcon-primary" name="addOrEditReturnPurchase" size={'lg'}>
                                    {loading &&
                                        <Spinner className='align-middle me-2' animation='border' size="sm" role='switch'/>
                                    }
                                    {t(lang, "warehouse.operation.item.common.button.add")}
                                </Button>
                            </div>
                            <span className="text-nowrap fw-bold my-auto fs-1">
                                {t(lang, "warehouse.operation.item.common.total_sum")}:
                                <span className="mx-1">{numeral.formats[numberFormat].format(totalSum)}</span>
                                <span className="text-info">{totalSumCurrencyName}</span>
                            </span>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>



            <Card className="bg-light mt-3 gap-2">
                <Card.Footer>
                    <Row>
                        <Col className="d-flex justify-content-between flex-wrap">
                            <div className="d-flex">
                                {returnPurchase?.items?.length &&
                                    <>
                                        {!returnPurchase?.is_approved &&
                                            <WarehouseOperationWithoutPayment
                                                onClick={onSubmitPay}
                                                id={returnPurchase?.id}
                                            />
                                        }
                                        <div>
                                            <Form.Control
                                                name="searchNameAndBarcode"
                                                placeholder={t(lang, "warehouse.operation.item.common.filter.search")}
                                                value={filter}
                                                onChange={(e) => setFilter(e.target.value)}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                            <div>
                                <h4 className="text-center">{t(lang, "warehouse.operation.item.common.list_sold")}</h4>
                            </div>
                            <div className="d-flex">
                                {!!returnPurchase?.payment && (
                                    <CommonDeleteModal
                                        className={'mx-1'}
                                        withConfirmation={true}
                                        onDelete={() => deleteReturnPurchasePayment(returnPurchase?.id)}
                                        deleteButtonLabel={t(lang, 'common.button.delete_payment')}
                                    />
                                )}

                                {returnPurchase?.items?.length &&
                                    // <WarehouseOperationPayment onSubmitPay={onSubmitPay} data={returnPurchase}/>
                                    <WOWithoutDiscountPayment
                                        data={returnPurchase}
                                        isEditing={isEditing}
                                        onSubmitPay={onSubmitPay}
                                    />
                                }
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="table-responsive fs--1 mt-3" style={{maxHeight: "560px"}}>
                                <Table className="border-bottom">
                                    <thead className="table-primary" style={{position: "sticky", top: '-1px'}}>
                                    <tr>
                                        <th className="align-middle">№</th>
                                        <th className="align-middle"
                                            style={{width: "300px"}}>{t(lang, "warehouse.operation.item.common.name")}</th>
                                        <th className="text-center">{t(lang, "warehouse.operation.item.common.image")}</th>
                                        <th className="align-middle">{t(lang, "warehouse.operation.item.common.quantity")}</th>
                                        <th className="text-start"
                                            style={{width: "300px"}}>{t(lang, "warehouse.operation.item.common.selling_price")}</th>
                                        <th className="align-middle">{t(lang, "warehouse.operation.item.common.total")}</th>
                                        {returnPurchase?.items?.some(item => item?.warehouse_item?.warehouse_item_use) &&
                                            <th className="text-center">{t(lang, "warehouse.operation.item.common.remainder_change")}</th>
                                        }
                                        {warehouses?.length > 1 &&
                                            <th className="text-start"
                                                style={{width: "230px"}}>{t(lang, "warehouse.operation.item.common.warehouse_name")}</th>
                                        }
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    {groupByItemPriceCurrencyId?.map((item, rowIndex) => {
                                        return (
                                            <React.Fragment key={rowIndex}>
                                                {item?.sub?.map((subItem, subRowIndex) => {
                                                    return (
                                                        <tr key={subItem.id} className="fw-bold" style={{height: "62px"}}>
                                                            <td key={rowIndex}
                                                                className={subRowIndex === 0 ? "text-start border-end border-2 ps-3" : "d-none"}
                                                                rowSpan={item?.sub?.length}
                                                                style={{width: "50px"}}
                                                            >
                                                                {groupByItemPriceCurrencyId?.length - rowIndex}
                                                            </td>
                                                            <td className={subRowIndex === 0 ? "text-start border-end border-2" : "d-none"}
                                                                rowSpan={item?.sub?.length}
                                                                style={{minWidth: "300px"}}
                                                                onDoubleClick={() => handleMouseOver({
                                                                    col: colIndexItem,
                                                                    row: rowIndex,
                                                                    subRow: subRowIndex
                                                                })}
                                                            >
                                                                {isHover[colCoordinateZero] === colIndexItem && isHover[rowCoordinateOne] === rowIndex && isHover[subRowoordinateTwo] === subRowIndex ?
                                                                    <WarehouseOperationItemSelect
                                                                        options={items}
                                                                        defaultValue={subItem?.warehouse_item?.id}
                                                                        menuPortalTarget={document.body}
                                                                        onChange={option => {
                                                                            updateItem({
                                                                                itemOne: option,
                                                                                item: subItem
                                                                            })
                                                                        }}
                                                                    />
                                                                    :
                                                                    <>
                                                                        {subItem?.warehouse_item?.name}
                                                                    </>
                                                                }
                                                            </td>
                                                            <td className={subRowIndex === 0 ? "text-center border-end border-2" : "d-none"}
                                                                style={{width: "50px"}}
                                                                rowSpan={item?.sub?.length}
                                                            >
                                                                {items.find(i => i?.item?.id === subItem?.warehouse_item?.id)?.item?.images?.length > 0 &&
                                                                    <WarehouseOperationImageModal
                                                                        image={items.find(i => i?.item?.id === item?.item?.id)?.item?.images}/>
                                                                }
                                                            </td>
                                                            <td className="text-start border-end border-2"
                                                                style={{maxWidth: "100px"}}
                                                                onDoubleClick={() => handleMouseOver({
                                                                    col: colIndexQuantity,
                                                                    row: rowIndex,
                                                                    subRow: subRowIndex
                                                                })}>
                                                                {isHover[colCoordinateZero] === colIndexQuantity && isHover[rowCoordinateOne] === rowIndex && isHover[subRowoordinateTwo] === subRowIndex ?
                                                                    <Form.Control
                                                                        placeholder={t(lang, "Quantity")}
                                                                        defaultValue={subItem?.quantity}
                                                                        onFocus={(e) => e.target.select()}
                                                                        autoFocus
                                                                        onBlur={(e) => {
                                                                            updateItem({
                                                                                quantity: e.target.value,
                                                                                item: subItem,
                                                                            })
                                                                        }}
                                                                    />
                                                                    :
                                                                    <>
                                                                        {subItem?.quantity}
                                                                    </>
                                                                }
                                                            </td>
                                                            <td className="text-start border-end border-2 text-nowrap"
                                                                style={{width: "200px"}}
                                                                onDoubleClick={() => handleMouseOver({
                                                                    col: colIndexPrice,
                                                                    row: rowIndex,
                                                                    subRow: subRowIndex
                                                                })}>
                                                                {isHover[colCoordinateZero] === colIndexPrice && isHover[rowCoordinateOne] === rowIndex && isHover[subRowoordinateTwo] === subRowIndex ?
                                                                    <Form.Control
                                                                        placeholder={t(lang, "Price")}
                                                                        defaultValue={subItem?.price?.amount}
                                                                        onFocus={(e) => e.target.select()}
                                                                        autoFocus
                                                                        onBlur={(e) => {
                                                                            updateItem({
                                                                                price: e.target.value,
                                                                                item: subItem,
                                                                            })
                                                                        }}
                                                                    />
                                                                    :
                                                                    <>
                                                                        {numeral.formats[numberFormat].format(subItem?.price?.amount)}
                                                                        <span
                                                                            className="text-info"> {subItem?.price?.currency?.name}</span>
                                                                    </>
                                                                }
                                                            </td>
                                                            <td className="text-start border-end border-2 text-nowrap"
                                                                style={{width: "300px"}}
                                                                onDoubleClick={() => handleMouseOver({
                                                                    col: colIndexTotal,
                                                                    row: rowIndex,
                                                                    subRow: subRowIndex
                                                                })}>
                                                                {isHover[colCoordinateZero] === colIndexTotal && isHover[rowCoordinateOne] === rowIndex && isHover[subRowoordinateTwo] === subRowIndex ?
                                                                    <Form.Control
                                                                        placeholder={t(lang, "total sum")}
                                                                        defaultValue={subItem?.quantity * subItem?.price?.amount}
                                                                        onFocus={(e) => e.target.select()}
                                                                        autoFocus
                                                                        onBlur={(e) => {
                                                                            updateItem({
                                                                                totalSum: +e.target.value,
                                                                                item: subItem,
                                                                            })
                                                                        }}
                                                                    />
                                                                    :
                                                                    <>
                                                                        {numeral.formats[numberFormat].format(subItem?.quantity * subItem?.price?.amount)}
                                                                        <span
                                                                            className="text-info"> {subItem?.price?.currency?.name}</span>
                                                                    </>
                                                                }
                                                            </td>
                                                            {groupByItemPriceCurrencyId[rowIndex]?.sub?.some(s => s?.warehouse_item?.warehouse_item_use) &&
                                                                <td className="text-center border-end border-2 text-nowrap">
                                                                    {subItem?.warehouse_item?.warehouse_item_use?.before_quantity}
                                                                    <span> => </span>
                                                                    {subItem?.warehouse_item?.warehouse_item_use?.after_quantity}
                                                                </td>
                                                            }
                                                            {warehouses?.length > 1 &&
                                                                <td className="text-start border-end border-2"
                                                                    style={{width: "240px"}}
                                                                    onDoubleClick={() => handleMouseOver({
                                                                        col: colIndexWarehouse,
                                                                        row: rowIndex,
                                                                        subRow: subRowIndex
                                                                    })}>
                                                                    {isHover[colCoordinateZero] === colIndexWarehouse && isHover[rowCoordinateOne] === rowIndex && isHover[subRowoordinateTwo] === subRowIndex && warehouses?.length > 1 ?
                                                                        <WarehouseOperationWarehouseSelect
                                                                            options={items?.find(f => f?.warehouse_states?.id === subItem?.warehouse_item?.id)?.warehouse_states?.warehouse_items}
                                                                            defaultValue={subItem?.warehouse_item?.warehouse?.id}
                                                                            onFocus={() => true}
                                                                            autoFocus={true}
                                                                            onBlur={(warehouse) => {
                                                                                updateItem({
                                                                                    warehouseId: warehouse?.id,
                                                                                    item: subItem,
                                                                                })
                                                                            }}
                                                                            onChange={warehouse => {
                                                                                updateItem({
                                                                                    warehouseId: warehouse?.id,
                                                                                    item: subItem,
                                                                                })
                                                                            }}
                                                                        />
                                                                        :
                                                                        <>
                                                                            {subItem?.warehouse_item?.warehouse?.name}
                                                                        </>
                                                                    }
                                                                </td>
                                                            }

                                                            <td className="text-nowrap text-center border-end border-2"
                                                                style={{width: "40px"}}>
                                                                {subItem?.is_deleted &&
                                                                    <span className="text-end text-nowrap text-danger">
                                                                        {t(lang, "warehouse.operation.item.common.removed")}
                                                                    </span>
                                                                }
                                                                <ReturnPurchaseUpdateItemModal returnPurchase={returnPurchase} item={subItem}
                                                                                     itemIndex={rowIndex}/>

                                                                <WarehouseOperationDeleteItem onClick={onDeleteItem}
                                                                                              itemId={subItem?.id}
                                                                                              id={returnPurchase?.id}/>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </React.Fragment>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col className="g-0 d-flex flex-column align-items-end">
                            <WarehouseOperationFooterCurrencyTotalSum
                                netPriceType={"debtHidden"}
                                netPrice={returnPurchase?.net_price}
                                pay={returnPurchase?.payment}
                            />
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>

        </>
    )
}

export default ReturnPurchaseForm;
