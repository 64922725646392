import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectLang, Translate} from "../../../app/store/reducers/main/mainReducer";
import {Modal, Spinner} from "react-bootstrap";
import FalconCloseButton from "../../common/FalconCloseButton";
import Button from "react-bootstrap/Button";
import {useHistory} from "react-router-dom";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import KanbanModal from "./kanban/KanbanModal";


const MarkContractorsDebtModal = ({show, setShow, operation, id, contractorId}) => {
    const lang = useSelector(selectLang)
    const t = Translate;
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [showTaskBoardModal, setShowTaskBoardModal] = useState(false);



    const onConfirmed = async () => {
        setLoading(true)
        setShowTaskBoardModal(true);
        setShow(false)
    }

    const handlerClose = () => {
        setShow(false)
        history.push(`/warehouse/operation/${operation}/view/${id}`)
    }

    return (
        <Fragment>

            <Modal show={show} onHide={handlerClose} backdrop="static">
                <Modal.Header>
                    <FalconCloseButton onClick={handlerClose}/>
                </Modal.Header>
                <Modal.Body>
                    <div className={'d-flex justify-content-center'}>
                        <FontAwesomeIcon icon={'triangle-exclamation'} size={'4x'} className={'text-warning'} />
                    </div>
                    <Modal.Title className="text-center">{t(lang, "task.board.contractor.debt.adding_a_task_for_debt_repayment_by_contractor")}</Modal.Title>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handlerClose}>
                        {t(lang, "warehouse.operation.item.common.without_payment_no")}
                    </Button>
                    <Button variant="primary" disabled={loading} onClick={() => onConfirmed()}>
                        {loading && <Spinner animation="border" size="sm" />}
                        {t(lang, "warehouse.operation.item.common.without_payment_yes")}
                    </Button>
                </Modal.Footer>
            </Modal>

            <KanbanModal
                show={showTaskBoardModal}
                setShow={setShowTaskBoardModal}
                // columnId={columnId}
                contractorId={contractorId}
                // boardId={boardId}
                fastAddTask={true}
                handlerClose={handlerClose}
            />

        </Fragment>
    );
};

export default MarkContractorsDebtModal;
