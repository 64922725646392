import React, {Fragment, useState} from 'react';
import IconAlert from "./IconAlert";
import {selectLang, Translate} from "../../app/store/reducers/main/mainReducer";
import {useSelector} from "react-redux";
import {Button} from "react-bootstrap";

const AlertPriceUpdate = () => {
    const t = Translate;
    const lang = useSelector(selectLang);

    const [show, setShow] = useState(true);
    const [moreText, setMoreText] = useState(false);

    const translatedText1 = t(lang, 'main.common.price_update_alert.notification_text.part.1', {
        price: '{price}',
    });
    const translatedText2 = t(lang, 'main.common.price_update_alert.notification_text.part.2', {
        hippo: '{hippo}',
        strong: '{strong}',
    });
    const translatedText3 = t(lang, 'main.common.price_update_alert.notification_text.part.3', {
        document_count: '{document_count}',
        price: '{price}',
    });
    const translatedText5 = t(lang, 'main.common.price_update_alert.notification_text.part.5', {
        hippo: '{hippo}',
    });

    return (
        <Fragment>
            {
                show && <IconAlert variant={'info'} dismissible onClose={() => setShow(false)}>
                    {
                        !moreText ?
                            <p className={'mb-0'}>
                                {
                                    translatedText1.split(/({price})/g).map((part, index) =>
                                    part === '{price}' ? <strong key={index} className={'fs-0'}>375 000</strong> : part)
                                }
                                <Button className={'fw-bold'} onClick={() => setMoreText(true)} variant={'link'}>{t(lang, 'edi.attorney.mass_import.show_more_btn.title')}</Button>
                            </p>
                            :
                            <p className={'mb-0'}>
                                {
                                    translatedText1.split(/({price})/g).map((part, index) =>
                                    part === '{price}' ? <strong key={index} className={'fs-0'}>375 000</strong> : part)
                                }
                                {
                                    translatedText2.split(/({hippo}|{strong})/g).map((part, index) =>
                                        part === '{hippo}' ? <strong key={index} className={'fs-0'}>Hippo.uz</strong> :
                                        part === '{strong}' ? <strong key={index} className={'fs-0'}>{t(lang, 'main.common.price_update_alert.notification_text.no.changes')}</strong> : part)
                                }
                                {
                                    translatedText3.split(/({document_count}|{price})/g).map((part, index) =>
                                        part === '{document_count}' ? <strong key={index} className={'fs-0'}>300</strong> :
                                        part === '{price}' ? <strong key={index} className={'fs-0'}>0,001</strong> : part)
                                }
                                {t(lang, 'main.common.price_update_alert.notification_text.part.4')}
                                <div className={'d-inline-block ms-3 '}>
                                    {
                                        translatedText5.split(/({hippo})/g).map((part, index) =>
                                            part === '{hippo}' ? <strong key={index} className={'fs-0'}>Hippo.uz</strong> : part)
                                    }
                                </div>
                                <div className={'text-end'}>
                                    <Button onClick={() => setMoreText(false)} className={'fw-bold'} variant={'link'}>{t(lang, 'edi.common.hide')}</Button>
                                </div>
                            </p>
                    }
                </IconAlert>
            }
        </Fragment>
    );
};

export default AlertPriceUpdate;
