import {Badge, Button, Card, Col, ListGroup, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {
    selectDateDayJSFormat,
    selectDateFormat,
    selectLang,
    selectNumberFormat,
    Translate
} from "../../../../../../app/store/reducers/main/mainReducer";
import React, {Fragment, useEffect, useState} from "react";
import numeral from "numeral";
import ContractorNote from "./ContractorNote";
import {
    loadContractorAsync,
    selectCardContractor,
    updateCardContractor, updateContractor, updateContractorDebtPromiseDate
} from "../../../../../../app/store/reducers/contractor/contractorReducer";
import {Link, useRouteMatch} from "react-router-dom";
import SoftBadge from "../../../../../common/SoftBadge";
import AddContractorPayment from "../../payment/AddContractorPayment";
import AddContractorPayout from "../../payout/AddContractorPayout";
import AddContractorLocationForm from "./AddContractorLocationForm";
import EventBus from "../../../../../../app/eventbus/EventBus";
import {
    LOCATION_ADD_SUCCESS,
    LOCATION_DELETE_SUCCESS,
    LOCATION_EDIT_SUCCESS
} from "../../../../../../app/eventbus/contractor/contractorLocationEvents";
import classNames from "classnames";
import EditContractorLocationForm from "./EditContractorLocationForm";
import DeleteContractorLocationModal from "./DeleteContractorLocationModal";
import dayjs from "dayjs";
import DebtRepayment from "../../DebtRepayment";
import UpdateDebtDate from "../../UpdateDebtDate";
import KanbanModal from "../../../../../kanban/modal/kanban/KanbanModal";
import {columnType} from "../../../../../../enum/KanbanWrapper";
import {
    getTask,
    selectBoards, selectWithTasks,
    updateModalContent
} from "../../../../../../app/store/reducers/kanban/kanbanReducer";

const ContractorGeneralDetails = () => {
    const dispatch = useDispatch()
    const t = Translate;
    const lang = useSelector(selectLang);
    const contractor = useSelector(selectCardContractor)
    const numberFormat = useSelector(selectNumberFormat);
    const dateFormat = useSelector(selectDateDayJSFormat);
    const [showDebtPromiseDate, setShowDebtPromiseDate] = useState(false);
    const [editTask, setEditTask] = useState(false);
    const [loading, setLoading] = useState(false);
    const {params: {id}} = useRouteMatch();
    const boards = useSelector(selectBoards);
    const withTasks = useSelector(selectWithTasks);

    const selectColumnType = columnType(contractor?.upcoming_debt_promise_task?.board_type, contractor?.upcoming_debt_promise_task?.board_column_type)

    let phones = []
    let emails = []
    if (contractor) {
        phones = contractor.contacts.filter(contact => contact.type === 1)
        emails = contractor.contacts.filter(contact => contact.type === 3)
    }
    const loadContractor = () => {
        loadContractorAsync(id).then(cr => {
            dispatch(updateCardContractor(cr))
        }).catch(err => console.log(err));
    };

    useEffect(() => {
        loadContractor()

        const onLocationAddedHandler = EventBus.on(LOCATION_ADD_SUCCESS, loadContractor)
        const onLocationEditedHandler = EventBus.on(LOCATION_EDIT_SUCCESS, loadContractor)
        const onLocationDeletedHandler = EventBus.on(LOCATION_DELETE_SUCCESS, loadContractor)

        return () => {
            EventBus.remove(LOCATION_ADD_SUCCESS, onLocationAddedHandler);
            EventBus.remove(LOCATION_EDIT_SUCCESS, onLocationEditedHandler);
            EventBus.remove(LOCATION_DELETE_SUCCESS, onLocationDeletedHandler);
        };
    }, [id]);

    const handlerClose = async () => {
        setLoading(true)
        setShowDebtPromiseDate(false)
        setEditTask(false)
        await loadContractorAsync(id)
            .then(cr => {
                dispatch(updateCardContractor(cr))
                dispatch(updateContractorDebtPromiseDate(cr))
            })
        setLoading(false)
    }

    const getTaskAsync = async () => {
        const {board_type, board_column_type, id} = contractor?.upcoming_debt_promise_task
        const board_id = boards.find(board => board.type === board_type)?.id

        const data = {
            board_column_id: withTasks.columns.find(column => column.type === board_column_type)?.id,
            board_column_task_id: id
        }

        await dispatch(getTask(board_id, data))
            .then(res => {
                dispatch(updateModalContent(res))
            })
            .finally(() => setLoading(false))
    }

    const handlerEditDebtDate = async () => {
        setLoading(true)
        setShowDebtPromiseDate(true)
        setEditTask(true)

        getTaskAsync()
    }



    return (
        <Fragment>
            <Row className="g-2">
                <Col xs={12}>
                    <Card>
                        <Card.Body>
                            <div className={"d-flex align-items-center justify-content-between border-bottom pb-3"}>
                                <div className={"d-flex flex-column me-3"}>
                                    <h6 className={"text-secondary"}>{t(lang, "edi.common.inn")}</h6>
                                    <h6>{contractor?.inn ? contractor?.inn : t(lang, "edi.common.not_specified")}</h6>
                                </div>
                                <div className={"d-flex flex-column me-3"}>
                                    <h6 className={"text-secondary"}>{t(lang, "crm.contractor.datatable.label")}</h6>
                                    <h6>{contractor?.labels.length > 0 ? contractor?.labels?.map((label, index) => {
                                        return (
                                            <SoftBadge className={'me-1'} key={index}>{label.name}</SoftBadge>
                                        )
                                    }) : 'Не указано'}</h6>
                                </div>
                                <div className={"d-flex flex-column me-3"}>
                                    <h6 className={"text-secondary text-center"}>{t(lang, "edi.contractor.datatable.header.row.state")}</h6>
                                    <div className={"h6"}>
                                        {contractor?.debts?.length > 0 ? contractor?.debts.map((item, index) => {
                                            return (
                                                <div className={'text-end'}
                                                     key={index}>{numeral.formats[numberFormat].format(item.amount)} <span
                                                    className="text-info">{item.currency.name}</span>
                                                </div>
                                            )
                                        }) : '0 сум'}
                                    </div>

                                    <div className={'mt-1 d-flex flex-column'}>
                                        <AddContractorPayment getTaskAsync={getTaskAsync} contractor={contractor}>
                                            <Button className={'my-1'} size={'sm'}
                                                    variant={'primary'}>{t(lang, 'crm.contractor_card.general.add_payment')}</Button>
                                        </AddContractorPayment>

                                        <AddContractorPayout>
                                            <Button className={'my-1'} size={'sm'}
                                                    variant={'warning'}>{t(lang, 'crm.contractor_card.general.add_payout')}</Button>
                                        </AddContractorPayout>
                                    </div>
                                </div>
                                <div className={"d-flex flex-column me-3"}>
                                    <div>
                                        <h6 className={"text-secondary text-center"}>{t(lang, "crm.contractor.datatable.debt_promise_date")}</h6>
                                        {contractor?.upcoming_debt_promise_task ? <div className={'d-flex justify-content-center align-items-center gap-2'}>
                                            <strong className={'fw-bold'}>{dayjs(new Date(contractor?.upcoming_debt_promise_task?.date), 'DD-MM-YYYY').format(dateFormat)}</strong>
                                            <Badge bg={selectColumnType?.variant}>
                                                {t(lang, selectColumnType?.name)}
                                            </Badge>
                                        </div> : <div className={'text-center'}>
                                            <SoftBadge bg={'warning'}>{t(lang, 'crm.contractor.datatable.debt_promise_date.date.repayment.not.specified')}</SoftBadge>
                                        </div>
                                        }
                                    </div>

                                    <div className={'mt-1 d-flex flex-column'}>
                                        {contractor?.upcoming_debt_promise_task ? <Fragment>
                                            <DebtRepayment getTaskAsync={getTaskAsync} contractorId={contractor?.id}>
                                                <Button className={'my-1'} size={'sm'} variant={'success'}>
                                                    {t(lang, 'crm.contractor.datatable.debt_promise_date.date.repayment.due.date')}
                                                </Button>
                                            </DebtRepayment>

                                            <Button className={'my-1'} size={'sm'} variant={'warning'} onClick={handlerEditDebtDate}>
                                                {t(lang, 'crm.contractor.datatable.debt_promise_date.date.repayment.changing.debt.date')}
                                            </Button>
                                        </Fragment> : <Fragment>
                                            <Button onClick={() => setShowDebtPromiseDate(true)} className={'my-1'} size={'sm'} variant={'primary'}>
                                                {t(lang, 'crm.contractor.datatable.set.debt')}
                                            </Button>
                                        </Fragment>}

                                    </div>
                                </div>
                                <div className={"d-flex flex-column me-3"}>
                                    <h6 className={"text-secondary"}>{t(lang, "edi.contractor_card.general.type")}</h6>
                                    {
                                        contractor?.type?.length > 0 ?
                                            contractor?.type.map((item, index) => {
                                                if (item === 1) {
                                                    return (
                                                        <h6 key={index}>{t(lang, "edi.common.buyer")}</h6>
                                                    )
                                                }
                                                if (item === 2) {
                                                    return (
                                                        <h6 key={index}>{t(lang, "edi.common.executor")}</h6>
                                                    )
                                                }
                                            }) : <h6>{t(lang, "edi.common.not_specified")}</h6>
                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} lg={4}>
                    <Card className="h-100">
                        <Card.Body>
                            <div className={"mb-3"}>
                                <div
                                    className={"text-secondary h6"}>{t(lang, "roaming.contract.company.info.phone")}</div>
                                {phones.length > 0 ? phones.map((ph, index) => {
                                    return (<div className={"h6 text-info"} key={index}>{ph.value}</div>)
                                }) : <div className={"h6"}>{t(lang, "edi.common.not_specified")}</div>}
                            </div>
                            <hr/>
                            <div>
                                <div
                                    className={"text-secondary h6"}>{t(lang, "edi.contractor_card.general.email")}</div>
                                {emails.length > 0 ? emails.map((ph, index) => {
                                    return (<div className={"h6 text-info"} key={index}>{ph.value}</div>)
                                }) : <div className={"h6"}>{t(lang, "edi.common.not_specified")}</div>}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} lg={8}>
                    <Card className="h-100">
                        <Card.Body>
                            <Row className="gy-3">
                                <Col xs={12} className="text-end">
                                    <AddContractorLocationForm contractor={contractor}/>
                                </Col>
                                <Col xs={12}>
                                    <ListGroup variant="flush" className="fw-normal fs--1 scrollbar pb-3"
                                               style={{maxHeight: '25rem'}}>
                                        {contractor?.locations.map((location) => (
                                            <ListGroup.Item key={location.id} className="border-0">
                                                <Link to="#"
                                                      className={classNames('notification flex justify-content-between align-items-center rounded-0')}
                                                >
                                                    <div className="notification-body">
                                                        <p className="mb-1 fw-bold text-dark">
                                                            {location.name}
                                                        </p>
                                                        <span className="notification-time">{location.latitude}, {location.longitude}</span>
                                                    </div>
                                                    <div>
                                                        <div className="mb-2">
                                                            <EditContractorLocationForm contractor={contractor}
                                                                                        location={location}
                                                            />
                                                        </div>
                                                        <div>
                                                            <DeleteContractorLocationModal contractor={contractor}
                                                                                           location={location}
                                                            />
                                                        </div>
                                                    </div>
                                                </Link>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12}>
                    <Card className="h-100">
                        <Card.Body>
                            <ContractorNote/>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <KanbanModal
                boardId={contractor?.upcoming_debt_promise_task?.board_id}
                loading={loading}
                edit={editTask}
                show={showDebtPromiseDate}
                setShow={setShowDebtPromiseDate}
                contractorId={id}
                fastAddTask={true}
                handlerClose={handlerClose}
            />
        </Fragment>
    )
}
export default ContractorGeneralDetails;
