//nds statuses
export const isNdsPayerCertificateActive = 20; //сертификат активный
export const isNdsPayerCertificatePending = 22; // сертификат временно неактивный

//invoice types
export const TypeDefault = 0
export const TypeAdditional = 1
export const TypeAmendOfExpenses = 2;
export const TypeWithoutPayment = 3;
export const TypeCorrective = 4
export const TypeCorrectiveAmendOfExpenses = 5;
export const TypeAdditionalAmendOfExpenses = 6;
export const TypeCorrectiveWithoutPayment = 8;
export const TypeAdditionalWithoutPayment = 9;

export const TypeConstruction = 1;


// single-member invoice options
export const ForPerson = 1;
export const ForForeignExportService = 2;
export const ForImportService = 3;
export const ForGovernmentSecret = 4;
export const ForFinancialService = 5;
export const BelowMarketPrice = 6;
export const BelowCustomsPrice = 7
export const ForExportService = 8;
export const OtherIncome = 9;
export const ExciseSubmissionForOwnNeeds = 10;
export const ExciseUsageForOwnNeeds = 11;
export const PriceAndPrimeCostDifference = 12;
export const LossAndDamageOfGoods = 13;

// income types
export const ExitValueOfTransferredGoods = 1;
export const RedemptionValueOfTransferredGoodsToShareholders = 2;
export const GoodAndServicesTransferAsCompensationToIndividuals = 3;
export const TransferOfGoodsAndOtherAssetsOnBailmentBasis = 4;
export const VoucherValueForRedeemableGoodsAndServices = 5;
export const ValueOfSoldFixedAssetsAndOtherProperty = 6;
export const RevenuesFromTheSaleOfBusinessAsGoingConcern = 7;




//mark types
export const Pipe = 1;
export const Alcohol = 2;
export const Beer = 3;
export const Medical = 4;
export const Water = 5;
export const Appliances = 6;

//mark package types
export const ForUsage = 1;
export const ForGroup = 2;
export const ForTransport = 3;

// origin types
export const IndividualManufacture = 1;
export const Resale = 2;
export const Service = 3;
export const NotIncluded = 4;

export const created_at = 'created_at'
export const updated_at = 'updated_at'

export const roamingInvoiceItemOriginTypes = [
    {value: IndividualManufacture, label: 'roaming.invoice.items.origin_types.1'},
    {value: Resale, label: 'roaming.invoice.items.origin_types.2'},
    {value: Service, label: 'roaming.invoice.items.origin_types.3'},
    {value: NotIncluded, label: 'roaming.invoice.items.origin_types.4'},
]

// invoice types
export const roamingInvoiceTypes = [
    {value: TypeDefault, label: 'roaming.invoice.send_form.type.option.0'},
    {value: TypeAdditional, label: 'roaming.invoice.send_form.type.option.1'},
    {value: TypeAmendOfExpenses, label: 'roaming.invoice.send_form.type.option.2'},
    {value: TypeWithoutPayment, label: 'roaming.invoice.send_form.type.option.3'},
    {value: TypeCorrective, label: 'roaming.invoice.send_form.type.option.4'},
    {value: TypeCorrectiveAmendOfExpenses, label: 'roaming.invoice.send_form.type.option.5'},
    {value: TypeAdditionalAmendOfExpenses, label: 'roaming.invoice.send_form.type.option.6'},
    {value: TypeCorrectiveWithoutPayment, label: 'roaming.invoice.send_form.type.option.7'},
    {value: TypeAdditionalWithoutPayment, label: 'roaming.invoice.send_form.type.option.8'},
    // {value: TypeConstruction, label: 'roaming.invoice.send_form.type.option.9'}
]

//one member invoice options(односторонняя счёт-фактура)
export const singleSidedInvoiceTypes = [
    {value: ForPerson, label: 'roaming.invoice.send_form.single_sided_type.option.1', disabled: false},
    {value: ForForeignExportService, label: 'roaming.invoice.send_form.single_sided_type.option.2', disabled: false},
    {value: ForImportService, label: 'roaming.invoice.send_form.single_sided_type.option.3', disabled: false},
    {value: ForGovernmentSecret, label: 'roaming.invoice.send_form.single_sided_type.option.4', disabled: false},
    {value: ForFinancialService, label: 'roaming.invoice.send_form.single_sided_type.option.5', disabled: false},
    {value: BelowMarketPrice, label: 'roaming.invoice.send_form.single_sided_type.option.6', disabled: false},
    {value: BelowCustomsPrice, label: 'roaming.invoice.send_form.single_sided_type.option.7', disabled: false},
    {value: ForExportService, label: 'roaming.invoice.send_form.single_sided_type.option.8', disabled: false},
    {value: OtherIncome, label: 'roaming.invoice.send_form.single_sided_type.option.9', disabled: false},
    {value: ExciseSubmissionForOwnNeeds, label: 'roaming.invoice.send_form.single_sided_type.option.10', disabled: false},
    {value: ExciseUsageForOwnNeeds, label: 'roaming.invoice.send_form.single_sided_type.option.11', disabled: false},
    {value: PriceAndPrimeCostDifference, label: 'roaming.invoice.send_form.single_sided_type.option.12', disabled: false},
    {value: LossAndDamageOfGoods, label: 'roaming.invoice.send_form.single_sided_type.option.13', disabled: false},
]

//nds options
export const ndsOptions = [
    {value: '', label: 'items.common.tax_rate_options.0'},
    {value: '0', label: '0'},
    {value: '12', label: '12'},
    {value: '15', label: '15'},
];


//mark types
export const markOptions = [
    {value: Pipe, label: 'roaming.common.items.mark_options.0'},
    {value: Alcohol, label: 'roaming.common.items.mark_options.1'},
    {value: Beer, label: 'roaming.common.items.mark_options.2'},
    {value: Medical, label: 'roaming.common.items.mark_options.3'},
    {value: Water, label: 'roaming.common.items.mark_options.4'},
    {value: Appliances, label: 'roaming.common.items.mark_options.5'},
]

//mark package types
export const markPackageOptions = [
    {value: ForUsage, label: 'roaming.common.items.mark_package_options.0'},
    {value: ForGroup, label: 'roaming.common.items.mark_package_options.1'},
    {value: ForTransport, label: 'roaming.common.items.mark_package_options.2'},
]

//income types
export const incomeTypes = [
    {value: ExitValueOfTransferredGoods, label: 'roaming.invoice.income_types.0'},
    {value: RedemptionValueOfTransferredGoodsToShareholders, label: 'roaming.invoice.income_types.1'},
    {value: GoodAndServicesTransferAsCompensationToIndividuals, label: 'roaming.invoice.income_types.2'},
    {value: TransferOfGoodsAndOtherAssetsOnBailmentBasis, label: 'roaming.invoice.income_types.3'},
    {value: VoucherValueForRedeemableGoodsAndServices, label: 'roaming.invoice.income_types.4'},
    {value: ValueOfSoldFixedAssetsAndOtherProperty, label: 'roaming.invoice.income_types.5'},
    {value: RevenuesFromTheSaleOfBusinessAsGoingConcern, label: 'roaming.invoice.income_types.6'}
]

//badge variants
const WARNING = "warning";
const DANGER = "danger";
const SUCCESS = "success";
const INFO = "info";
const PRIMARY = "primary";
const SECONDARY = "secondary";
const DARK = "dark";

//invoice statuses
export const StatusSentToCustomer = 15;
export const StatusCanceledByExecutor = 17;
export const StatusDeclinedByCustomerOrPerson = 20;
export const StatusAcceptedByCustomerOrPerson = 30;
export const StatusRevoked = 40; //revoked


export const VIEWER_CUSTOMER = "inbox";
export const VIEWER_EXECUTOR = "outbox";
export const VIEWER_PERSON = "person";
export const DRAFT = "draft";

export const defaultRoamingInvoiceDatatableColumns = [
    {
        "order": 0,
        "column": "index",
        "active": true
    },
    {
        "order": 1,
        "column": "number",
        "active": true
    },
    {
        "order": 2,
        "column": "total_sum",
        "active": true
    },
    {
        "order": 3,
        "column": "date",
        "active": true
    },
    {
        "order": 4,
        "column": "status",
        "active": true
    },
    {
        "order": 5,
        "column": "created_date",
        "active": true
    },
    {
        "order": 6,
        "column": "contractor",
        "active": true
    },
    {
        "order": 7,
        "column": "contract_num",
        "active": true
    },
]

export class RoamingInvoiceWrapper {
    _roamingInvoice;

    constructor(invoice) {
        this._roamingInvoice = invoice;
    }

    //form
    getTaxGapInfoBadgeVariant(ndsStatusInfo, taxGapInfo) {
        if (ndsStatusInfo) {
            if (!ndsStatusInfo.is_vat_payer)
                return SUCCESS;
            if (taxGapInfo) {
                let taxGap = taxGapInfo.taxGap;
                if (taxGap === null || taxGap < 0.2)
                    return SUCCESS;
                if (taxGap > 0.2 && taxGap < 0.5)
                    return INFO;
                if (taxGap > 0.5 && taxGap < 0.7)
                    return WARNING;
                if (taxGap > 0.7)
                    return DANGER;
            }
        }
    }

    getTaxGapInfoText(ndsStatusInfo, taxGapInfo) {
        if (ndsStatusInfo) {
            if (!ndsStatusInfo.is_vat_payer)
                return "roaming.invoice.no_tax_gap";
            if (taxGapInfo)
                return taxGapInfo.taxGap;
        }
    }

    getCommittentInnColspan(hasExcise, hasMarking, hasMedical) {
        if (hasExcise && !hasMarking && !hasMedical) {
            return 5;
        } else if (hasExcise && hasMarking && !hasMedical) {
            return 5;
        } else if (hasExcise && hasMarking && hasMedical) {
            return 6;
        } else if (hasMarking && !hasExcise && !hasMedical) {
            return 4;
        } else if (hasMarking && hasMedical && !hasExcise) {
            return 5;
        } else if (!hasMarking && hasMedical && hasExcise) {
            return 6;
        } else if (!hasMarking && !hasExcise && hasMedical) {
            return 5;
        } else {
            return 4;
        }
    }

    getCommittentNameColspan(hasExcise, hasMarking, hasMedical) {
        if (hasExcise && !hasMarking && !hasMedical) {
            return 5;
        } else if (hasExcise && hasMarking && !hasMedical) {
            return 6;
        } else if (hasExcise && hasMarking && hasMedical) {
            return 7;
        } else if (hasMarking && !hasExcise && !hasMedical) {
            return 5;
        } else if (hasMarking && hasMedical && !hasExcise) {
            return 6;
        } else if (!hasMarking && hasMedical && hasExcise) {
            return 6;
        } else if (!hasMarking && !hasExcise && hasMedical) {
            return 5;
        } else {
            return 4;
        }
    }

    getCommittentNdsCodeColspan(hasExcise, hasMarking, hasMedical) {
        if (hasExcise && !hasMarking && !hasMedical) {
            return 5;
        } else if (hasExcise && hasMarking && !hasMedical) {
            return 6;
        } else if (hasExcise && hasMarking && hasMedical) {
            return 6;
        } else if (hasMarking && !hasExcise && !hasMedical) {
            return 4;
        } else if (hasMarking && hasMedical && !hasExcise) {
            return 5;
        } else if (!hasMarking && hasMedical && hasExcise) {
            return 6;
        } else if (!hasMarking && !hasExcise && hasMedical) {
            return 5;
        } else {
            return 4;
        }
    }


    //view
    getTaxGapBadgeVariant(ndsStatus, taxGap) {
        if (!ndsStatus)
            return SUCCESS;
        if (taxGap === null || taxGap < 0.2)
            return SUCCESS;
        if (taxGap > 0.2 && taxGap < 0.5)
            return INFO;
        if (taxGap > 0.5 && taxGap < 0.7)
            return WARNING;
        if (taxGap > 0.7)
            return DANGER;
    }

    getTaxGapText(ndsStatus, taxGap) {
        if (!ndsStatus)
            return "roaming.invoice.no_tax_gap";
        return taxGap;
    }

    //form
    getNdsInfo(ndsStatusInfo) {
        let ndsInfo = {code: null, status: null};
        if (ndsStatusInfo && ndsStatusInfo.is_safe) {
            switch (true) {
                case ndsStatusInfo.is_suspended:
                    ndsInfo = {code: ndsStatusInfo.registration_code, status: isNdsPayerCertificatePending};
                    break;
                case ndsStatusInfo.is_active:
                    ndsInfo = {code: ndsStatusInfo.registration_code, status: isNdsPayerCertificateActive};
                    break;
                default:
                    break;
            }
        }
        return ndsInfo;
    }


    getNdsStatus(ndsStatusInfo) {
        if (ndsStatusInfo?.is_vat_payer) {
            if (ndsStatusInfo?.is_safe) {
                switch (true) {
                    case ndsStatusInfo?.is_suspended:
                        return isNdsPayerCertificatePending; //22
                    case ndsStatusInfo?.is_active:
                        return isNdsPayerCertificateActive; //20
                    default:
                        return null;
                }
            }
        }
        return null;
    }

    getNdsStatusText(ndsStatusInfo, company) {
        let ndsStatus;
        if (ndsStatusInfo) {
            ndsStatus = this.getNdsStatus(ndsStatusInfo);
            return this.getNdsText(ndsStatus, company);
        }
    }

    getNdsStatusBadgeVariant(ndsStatusInfo, company) {
        let ndsStatus;
        if (ndsStatusInfo) {
            ndsStatus = this.getNdsStatus(ndsStatusInfo);
            return this.getNdsBadgeVariant(ndsStatus, company)
        }
    }

    //view
    getNdsText(status, company) {
        if (company && company.person_num)
            return "roaming.invoice.nds_status.person";

        //todo: hotfix cannot compare string vs number, to prevent added hotfix code
        if (typeof(status) === "string")
            status = +status

        switch (status) {
            case isNdsPayerCertificateActive:
                return "roaming.invoice.nds_status.20";
            case isNdsPayerCertificatePending:
                return "roaming.invoice.nds_status.22";
            default:
                return "roaming.invoice.nds_status.default";
        }
    }

    getNdsBadgeVariant(status, company) {
        if (company && company.person_num)
            return WARNING;

        //todo: hotfix cannot compare string vs number, to prevent added hotfix code
        if (typeof(status) === "string")
            status = +status

        switch (status) {
            case isNdsPayerCertificateActive:
                return SUCCESS;
            case isNdsPayerCertificatePending:
                return DANGER;
            default:
                return PRIMARY;
        }
    }

    getStatusText(inn) {
        if (this._roamingInvoice.executor.inn === inn) {
            if (!this._roamingInvoice.status)
                return "roaming.act.datatable.viewer.draft"
            switch (this._roamingInvoice.status) {
                case StatusSentToCustomer:
                    return "roaming.invoices.invoice.status.executor.15"
                case StatusCanceledByExecutor:
                    return "roaming.invoices.invoice.status.executor.17"
                case StatusDeclinedByCustomerOrPerson:
                    return "roaming.invoices.invoice.status.executor.20"
                case StatusAcceptedByCustomerOrPerson:
                    return "roaming.invoices.invoice.status.executor.30"
                case StatusRevoked:
                    return "roaming.invoices.invoice.status.executor.40"
                default:
                    return "roaming.invoices.roaming_statuses.not_exist"
            }
        } else if (this._roamingInvoice.customer.inn === inn) {
            switch (this._roamingInvoice.status) {
                case StatusSentToCustomer:
                    return "roaming.invoices.invoice.status.customer.15"
                case StatusCanceledByExecutor:
                    return "roaming.invoices.invoice.status.customer.17"
                case StatusDeclinedByCustomerOrPerson:
                    return "roaming.invoices.invoice.status.customer.20"
                case StatusAcceptedByCustomerOrPerson:
                    return "roaming.invoices.invoice.status.customer.30"
                case StatusRevoked:
                    return "roaming.invoices.invoice.status.customer.40"
                default:
                    return "roaming.invoices.roaming_statuses.not_exist"
            }
        } else {
            switch (this._roamingInvoice.status) {
                case StatusSentToCustomer:
                    return "roaming.invoices.invoice.status.person.15"
                case StatusCanceledByExecutor:
                    return "roaming.invoices.invoice.status.person.17"
                case StatusDeclinedByCustomerOrPerson:
                    return "roaming.invoices.invoice.status.person.20"
                case StatusAcceptedByCustomerOrPerson:
                    return "roaming.invoices.invoice.status.person.30"
                case StatusRevoked:
                    return "roaming.invoices.invoice.status.person.40"
                default:
                    return "roaming.invoices.roaming_statuses.not_exist"
            }
        }
    }

    getStatusVariant(inn) {
        if (this._roamingInvoice.executor.inn === inn) {
            switch (this._roamingInvoice.status) {
                case StatusSentToCustomer:
                    return INFO;
                case StatusCanceledByExecutor:
                    return WARNING;
                case StatusDeclinedByCustomerOrPerson:
                    return DANGER;
                case StatusAcceptedByCustomerOrPerson:
                    return SUCCESS;
                case StatusRevoked:
                    return SECONDARY;
                default:
                    return DARK;
            }
        } else if (this._roamingInvoice.customer.inn === inn) {
            switch (this._roamingInvoice.status) {
                case StatusSentToCustomer:
                    return WARNING;
                case StatusCanceledByExecutor:
                    return DANGER;
                case StatusDeclinedByCustomerOrPerson:
                    return DANGER;
                case StatusAcceptedByCustomerOrPerson:
                    return SUCCESS;
                case StatusRevoked:
                    return SECONDARY
                default:
                    return DARK;
            }
        } else {
            switch (this._roamingInvoice.status) {
                case StatusSentToCustomer:
                    return INFO;
                case StatusCanceledByExecutor:
                    return DANGER;
                case StatusDeclinedByCustomerOrPerson:
                    return DANGER;
                case StatusAcceptedByCustomerOrPerson:
                    return SUCCESS;
                case StatusRevoked:
                    return SECONDARY;
                default:
                    return DARK;
            }
        }
    }

    getStatuses(viewer) {
        let statuses = [];
        switch (viewer) {
            case VIEWER_EXECUTOR:
                statuses = [
                    {id: 0, name: "roaming.invoices.invoice.status.all"},
                    {id: StatusSentToCustomer, name: "roaming.invoices.invoice.status.executor.15"},
                    {id: StatusCanceledByExecutor, name: "roaming.invoices.invoice.status.executor.17"},
                    {id: StatusDeclinedByCustomerOrPerson, name: "roaming.invoices.invoice.status.executor.20"},
                    {id: StatusAcceptedByCustomerOrPerson, name: "roaming.invoices.invoice.status.executor.30"},
                    {id: StatusRevoked, name: "roaming.invoices.invoice.status.executor.40"},
                ]
                break;
            case VIEWER_CUSTOMER:
                statuses = [
                    {id: 0, name: "roaming.invoices.invoice.status.all"},
                    {id: StatusSentToCustomer, name: "roaming.invoices.invoice.status.customer.15"},
                    {id: StatusCanceledByExecutor, name: "roaming.invoices.invoice.status.customer.17"},
                    {id: StatusDeclinedByCustomerOrPerson, name: "roaming.invoices.invoice.status.customer.20"},
                    {id: StatusAcceptedByCustomerOrPerson, name: "roaming.invoices.invoice.status.customer.30"},
                    {id: StatusRevoked, name: "roaming.invoices.invoice.status.customer.40"},
                ]
                break;
            case VIEWER_PERSON:
                statuses = [
                    {id: 0, name: "roaming.invoices.invoice.status.all"},
                    {id: StatusSentToCustomer, name: "roaming.invoices.invoice.status.person.15"},
                    {id: StatusCanceledByExecutor, name: "roaming.invoices.invoice.status.person.17"},
                    {id: StatusDeclinedByCustomerOrPerson, name: "roaming.invoices.invoice.status.person.20"},
                    {id: StatusAcceptedByCustomerOrPerson, name: "roaming.invoices.invoice.status.person.30"},
                    {id: StatusRevoked, name: "roaming.invoices.invoice.status.person.40"},
                ]
                break;
        }
        return statuses;
    }

    getStatusVariants(viewer, id) {
        let variants = [];
        switch (viewer) {
            case VIEWER_EXECUTOR:
                variants = [
                    {id: 0, name: PRIMARY},
                    {id: StatusSentToCustomer, name: INFO},
                    {id: StatusCanceledByExecutor, name: WARNING},
                    {id: StatusDeclinedByCustomerOrPerson, name: DANGER},
                    {id: StatusAcceptedByCustomerOrPerson, name: SUCCESS},
                    {id: StatusRevoked, name: SECONDARY},

                ]
                break;
            case VIEWER_CUSTOMER:
                variants = [
                    {id: 0, name: PRIMARY},
                    {id: StatusSentToCustomer, name: WARNING},
                    {id: StatusCanceledByExecutor, name: DANGER},
                    {id: StatusDeclinedByCustomerOrPerson, name: DANGER},
                    {id: StatusAcceptedByCustomerOrPerson, name: SUCCESS},
                    {id: StatusRevoked, name: SECONDARY},
                ]
                break;
            case VIEWER_PERSON:
                variants = [
                    {id: 0, name: "roaming.invoices.invoice.status.all"},
                    {id: StatusSentToCustomer, name: "roaming.invoices.invoice.status.person.15"},
                    {id: StatusCanceledByExecutor, name: "roaming.invoices.invoice.status.person.17"},
                    {id: StatusDeclinedByCustomerOrPerson, name: "roaming.invoices.invoice.status.person.20"},
                    {id: StatusAcceptedByCustomerOrPerson, name: "roaming.invoices.invoice.status.person.30"},
                    {id: StatusRevoked, name: "roaming.invoices.invoice.status.person.40"},
                ]
                break;
        }
        return variants.find(v => v.id === id).name;
    }

    GetViewers() {
        return [
            {id: VIEWER_CUSTOMER, name: this.GetViewerText(VIEWER_CUSTOMER)},
            {id: VIEWER_EXECUTOR, name: this.GetViewerText(VIEWER_EXECUTOR)},
            {id: VIEWER_PERSON, name: this.GetViewerText(VIEWER_PERSON)},
            {id: DRAFT, name: this.GetViewerText(DRAFT)},
        ];
    }

    GetViewerText(viewer) {
        switch (viewer) {
            case VIEWER_CUSTOMER:
                return "roaming.common.viewer.incoming";
            case VIEWER_EXECUTOR:
                return "roaming.common.viewer.outcoming";
            case VIEWER_PERSON:
                return "roaming.common.viewer.agent";
            case DRAFT:
                return "roaming.common.viewer.draft";
            default:
                return;
        }
    };

    GetViewerIcon(viewer) {
        switch (viewer) {
            case VIEWER_CUSTOMER:
                return "arrow-down";
            case VIEWER_EXECUTOR:
                return "arrow-up";
            case VIEWER_PERSON:
                return "user-circle";
            case DRAFT:
                return "bookmark";
            default:
                return;
        }
    };
}


const roamingInvoiceWrapper = new RoamingInvoiceWrapper();


export default roamingInvoiceWrapper;
